import * as React from 'react';
import { Typography, Link } from '@mui/material';
import {PropsWithChildren} from "react";

interface PaperViewerProps {
    title: string;
    link: string;
}

export const PaperViewerWrapper: React.FunctionComponent<PropsWithChildren<PaperViewerProps>> = (props) => {
    return (
        <>
            <Typography align={"justify"} sx={{ color:"#555",fontWeight: 600,fontSize:12}}>
                <Link
                    href={props.link}
                    underline="hover"
                    align="justify"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {props.title}
                </Link>
            </Typography>
            {props.children}
        </>
    );
};
