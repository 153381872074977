import {Point} from "../../../types/PlotData";
import {StoreActionType, StoreState} from "../../store";
import {StoreActionReducer} from "../../reducer";

export interface StoreActionSetLastHovered {
    type: StoreActionType.SET_LAST_HOVERED;
    lastHovered: Point;
}

export const setLastHoveredAction = (lastHovered: Point): StoreActionSetLastHovered => ({
    type: StoreActionType.SET_LAST_HOVERED,
    lastHovered
});

export const setLastHoveredReducer: StoreActionReducer<StoreActionType.SET_LAST_HOVERED> = (state, action): StoreState | void => {
    state.graphStatus.lastHoveredPoint = action.lastHovered;
};
