import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

export const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));

interface ModalDialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const ModalDialogTitle: React.FunctionComponent<ModalDialogTitleProps> = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <div>
            <DialogTitle component="h6" sx={{ fontWeight: 'bold', m: 0, p: 2 ,paddingLeft: (theme) =>theme.spacing(8),}} {...other} >
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        </div>
    );
}
