import * as React from 'react';
import {DocumentType, ScicartaDocument} from "../../types/DocumentData";
import {PaperSelectedInformationClinicalTrial} from "./paperSelectedTypes/PaperSelectedInformationClinicalTrial";
import {PaperSelectedInformationPubMed} from "./paperSelectedTypes/PaperSelectedInformationPubMed";

interface PaperListInformationSorterProps {
    documents: ReadonlyArray<ScicartaDocument>;
    getTextByItem: (item: ScicartaDocument)=>string;
    onDeleteDocument: (documentId: string) => void;
}

export const PaperListInformationSorter: React.FunctionComponent<PaperListInformationSorterProps> = (props) => {
    const documents = props.documents;
    return <>{
        documents.map((document) => {
            switch (document.type) {
                case DocumentType.CLINICAL_TRIAL:
                    return <PaperSelectedInformationClinicalTrial key={document.id} onDeleteDocument={props.onDeleteDocument} document={ document } getTextByItem={props.getTextByItem}/>
                case DocumentType.PUBMED:
                    return <PaperSelectedInformationPubMed key={document.id} onDeleteDocument={props.onDeleteDocument} document={ document } getTextByItem={props.getTextByItem}/>;
            }
            })
    }</>
};
