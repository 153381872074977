export type TreeFilter = {
    content: Array<TreeFilterElement>;
}

export enum TreeFilterType {
    GROUP = 'group',
    ITEM = 'item'
}

type TreeFilterGroup = {
    type: TreeFilterType.GROUP;
    name: string;
    content: Array<TreeFilterElement>;
}

type TreeFilterItem = {
    type: TreeFilterType.ITEM;
    id: string;
    name: string;
    foundCount: number;
    foundAlias: Array<string>;
}

export type TreeFilterElement = TreeFilterItem | TreeFilterGroup;
