import * as React from 'react';
import {PaperViewerWrapper} from "../PaperViewerWrapper";
import {PaperViewerParagraph} from "../PaperViewerParagraph";
import {Box} from '@mui/material';
import {DocumentPubmed} from "../../../types/DocumentData";
import {PaperViewerDocumentProps} from "./PaperViewerDocumentProps";
import {useStyledWords} from "./useStyledWords";
import {useStyledChunks} from "./useStyledChunks";
import {abbreviate} from "../../../utils/Number";
import {joinOrUndefined} from "../../../utils/Array";

const extractAuthors = (authors: Array<string>): [string, string] | [string] | undefined => {
    if (authors.length === 0) {
        return undefined;
    } else if (authors.length === 1) {
        return [authors[0]];
    } else {
        return [authors[0], authors[authors.length - 1]];
    }
}

export const PaperViewerDocumentPubmed: React.FunctionComponent<PaperViewerDocumentProps<DocumentPubmed>> = (props) => {

    const searchStyles = useStyledWords(props.appQuery);
    const chunks = useStyledChunks(props.document.highlighting, props.appQuery.filters);

    const authors = extractAuthors(props.document.authors);
    const companyAffiliations = joinOrUndefined(props.document.affiliation, ', ');
    const allAuthors = joinOrUndefined(props.document.authors, ', ');
    const mesh = joinOrUndefined(props.document.mesh, ', ');
    const substances = joinOrUndefined(props.document.substances, ', ');
    const keywords = joinOrUndefined(props.document.keywords, ', ');

    return (
        <Box mr={2}>
                <PaperViewerWrapper title={props.document.title} link={props.document.doi_href}>
                    {authors && <PaperViewerParagraph title={"First, Last Authors:"}
                                          content={authors.join(', ')}
                                          styledWords={searchStyles}
                                          isInline
                    />}
                    <PaperViewerParagraph title={"Journal:"}
                                          content={props.document.journal}
                                          styledWords={searchStyles}
                                          styledChunks={chunks.journal}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Paper id:"}
                                          content={`PMID: ${props.document.id}`}
                                          isInline
                    />
                    {companyAffiliations && <PaperViewerParagraph title={"Company Affiliations:"}
                                          content={companyAffiliations}
                                          styledWords={searchStyles}
                                          isInline
                    />}
                    <PaperViewerParagraph title={"Abstract"}
                                          content={props.document.abstract}
                                          styledWords={searchStyles}
                                          styledChunks={chunks.abstract}
                    />
                    {allAuthors && <PaperViewerParagraph title={"All Authors:"}
                                          content={allAuthors}
                                          styledWords={searchStyles}
                                          isInline
                    />}
                    {keywords && <PaperViewerParagraph title={"Keywords:"}
                                          content={keywords}
                                          styledWords={searchStyles}
                                          isInline
                    />}
                    <PaperViewerParagraph title={"Publication Type"}
                                          content={props.document.publication_type }
                                          styledWords={searchStyles}
                                          styledChunks={chunks.publication_type}
                    />
                    {mesh && <PaperViewerParagraph title={"MeSH"}
                                          content={props.document.mesh.map((text) => text ).join(', ')}
                                          styledWords={searchStyles}
                    />}
                    {substances && <PaperViewerParagraph title={"Substances"}
                                          content={props.document.substances.map((text) => text ).join(', ')}
                                          styledWords={searchStyles}
                    />}
                    <PaperViewerParagraph title={"Pubmed citations:"}
                                          content={abbreviate(props.document.citations)}
                                          isInline
                    />
                </PaperViewerWrapper>
        </Box>
    );
};
