import {TreeMenuItem} from "react-simple-tree-menu";
import * as React from "react";
import {Stack} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {style} from "typestyle";
import {abbreviate} from "../../utils/Number";
import {SelectedMenuItem} from "./SelectedMenuItem";
import {TreeNodeScicarta} from "./Types";

// Todo: Instead of this the API should let us know
const FIRST_AUTHOR = 'First author:';
const LAST_AUTHOR = 'Last author:';

const menuItemClassName = style({
    cursor: 'pointer',
    listStyleType:"none"
});

interface MenuItemProps extends TreeMenuItem {
    label: string;
    selected: boolean;
}

export const MenuItem: React.FunctionComponent<MenuItemProps> = props => {
    // Menu items are also TreeNodeScicarta elements
    const count = (props as unknown as TreeNodeScicarta).count ?? 0;

    let label = props.label;

    if (label.startsWith(FIRST_AUTHOR)) {
        label = label.slice(FIRST_AUTHOR.length);
    } else if (label.startsWith(LAST_AUTHOR)) {
        label = label.slice(LAST_AUTHOR.length);
    }

    return (
        <li className={ menuItemClassName } onClick={ props.hasNodes ? props.toggleNode : props.onClick } style={ {
            paddingLeft: `${props.level + (props.hasNodes ? 0 : 1)}em`
        } }>
            <Stack direction="row">
                { props.hasNodes && (props.isOpen ? <ExpandMore/> : <ChevronRightIcon/>) }
                <SelectedMenuItem selected={props.selected}>
                    { label } { count ? ` - ${abbreviate(count)}` : ''}
                </SelectedMenuItem>
            </Stack>
        </li>
    );
};
