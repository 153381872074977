import * as React from 'react';
import { highLightChunks, StyledChunks, StyledWords } from './HighLightReconciliation';
import {useMemo} from "react";

const highlightText = (text: string, keywords: ReadonlyArray<StyledWords>, initialStyledChunks?: ReadonlyArray<StyledChunks>): string => {
    const chunks: ReadonlyArray<StyledChunks> = highLightChunks(text, keywords, initialStyledChunks);

    return chunks.map(chunk => {
        const { end, style, start } = chunk;
        const item = text.substring(start, end);
        if (style) {
            let result = [ item ];
            if (style.bold) {
                result = [ '<strong>', ...result, '</strong>' ];
            }
            if (style.color) {
                if (style.color.length > 1) {
                    // see: https://css-tricks.com/stripes-css/
                    let cssStyle = 'background: repeating-linear-gradient(45deg,';
                    for (let i = 0; i < style.color.length; i++) {
                        const startOffset = i > 0 ? `${i*10}px`: '';
                        const endOffset = `${(i+1)*10}px`;
                        cssStyle +=`${style.color[i]} ${startOffset}, ${style.color[i]} ${endOffset}`;

                        if (i + 1 < style.color.length) {
                            cssStyle += ', ';
                        }
                    }
                    cssStyle += ');'

                    result = [ `<span style="${ cssStyle }">`, ...result, '</span>' ];
                } else {
                    result = [ `<span style="background-color:${ style.color }">`, ...result, '</span>' ];
                }
            }

            return result.join('');
        } else {
            return item;
        }
    }).join('');
};

interface HighLightTextProps {
    text: string;
    keywords?: Array<StyledWords>;
    styledChunks?: Array<StyledChunks>;
    component?: React.ElementType;
}

export const HighlightText: React.FunctionComponent<HighLightTextProps> = (props) => {
    const Component = props.component || 'span';
    const html = React.useMemo(() => {
        if (!props.text) {
            return '';
        }

        return highlightText(props.text, props.keywords ?? [], props.styledChunks ?? []);
    }, [ props.keywords, props.text, props.styledChunks ]);

    return <Component dangerouslySetInnerHTML={ { __html: html } }/>;
};

export interface HighlightingOptions {
    chunks?: ReadonlyArray<StyledChunks>;
    words?: ReadonlyArray<StyledWords>;
}

const optionsDefault: HighlightingOptions = {

}
export const useHighlighting = (text: string, options?: HighlightingOptions) => {
    const { chunks, words } = options ?? optionsDefault;
    return useMemo(() => {
        if (chunks || words) {
            return highlightText(text, words ?? [], chunks);
        }

        return text;
    }, [text, chunks, words]);
};
