import {Point} from "../../../types/PlotData";
import {StoreActionType, StoreState} from "../../store";
import {StoreActionReducer} from "../../reducer";

export interface StoreActionSetLastClicked {
    type: StoreActionType.SET_LAST_CLICKED;
    lastClicked: Point;
}

export const setLastClickedAction = (lastClicked: Point): StoreActionSetLastClicked => ({
    type: StoreActionType.SET_LAST_CLICKED,
    lastClicked
});

export const setLastClickedReducer: StoreActionReducer<StoreActionType.SET_LAST_CLICKED> = (state, action): StoreState | void => {
    state.graphStatus.lastClickedPoint = action.lastClicked;
};
