import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Fill, Top, TopResizable, CenterType, Right} from "react-spaces";
import {Graph} from "./Graph";
import {PlotDataResponse} from "../../types/PlotData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragHandle from '@mui/icons-material/DragHandle';
import {useState} from "react";
import {useWindowSize} from "react-use";
import {Box} from "@mui/material";

interface GraphContainerProps {
    plotData?: PlotDataResponse;
}

export const GraphContainer: React.FunctionComponent<GraphContainerProps> = props => {
    const [ sidebarExpanded, setSidebarExpanded ] = useState(true);
    const { height} = useWindowSize();

    const graph = {
        borderTop: "2px solid #ccc",
        borderBottom: "2px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        background: "white",
    };

    const graphTop = {
        borderLeft: "2px solid #ccc",
        borderRight: "2px solid #ccc",
        borderBottom: "2px solid #ccc",
        background: "white",
    };

    return (
        <TopResizable
            size={sidebarExpanded ? "60%" : "32px"}
            style={graph}
            order={3}
            trackSize={true}
            maximumSize={height/2}
            minimumSize={Math.round(0.2 * height)}
            handleRender={(props) => {
                return <div {...props} style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <DragHandle style={{
                        position: 'absolute',
                        transform: 'translateY(-35%)'
                    }} />
                </div>
            }}
        >
            <Top size="30px" style={graphTop}>
                <Fill centerContent={CenterType.Vertical} >
                    <Box sx={{marginLeft:"16px",fontSize:10,color:"#87868a!important"}}>
                        <Typography variant="subtitle1" >
                            Currently only analyzing the most recent 1000 papers and 500 trials.
                        </Typography>
                    </Box>
                </Fill>
                <Right
                    size={25}
                    centerContent={CenterType.HorizontalVertical}
                    onClick={() => setSidebarExpanded(prev => !prev)}>
                    {sidebarExpanded? <ArrowDropDownIcon sx={{color:"#87868a!important"}}/>:<ArrowDropUpIcon sx={{color:"#87868a!important"}}/> }
                </Right>
            </Top>
            <Fill trackSize>
                <Graph
                    plotData={ props.plotData }
                />
            </Fill>
        </TopResizable>


    );
};
