import * as React from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
    OutlinedInput, Box, Skeleton
} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import {ExampleQueryButton} from "../reusableComponent/ExampleQueryButton";
import {useSelector} from "react-redux";
import {getAvailableSources, getDocumentCount, getQuery, getSelectedSources} from "../../store/selectors";
import {useStoreDispatch} from "../../store/store";
import {setQueryAction} from "../../store/actions/setQuery";
import Config from '../../Config';
import {setAppQueryAction} from "../../store/actions/setAppQuery";
import {SourceSelector} from "./SourceSelector";
import {addSourceAction} from "../../store/actions/addSource";
import {removeSourceAction} from "../../store/actions/removeSource";
import {useDebounce} from "react-use";
import {useEffect, useState} from "react";

export const PublicationsQuery: React.FunctionComponent = () => {
    const queryValue = useSelector(getQuery);
    const available = useSelector(getAvailableSources);
    const selected = useSelector(getSelectedSources);
    const documentCount = useSelector(getDocumentCount);
    const dispatch = useStoreDispatch();
    const [localQuery, setLocalQuery] = useState(queryValue);

    useEffect(() => {
        setLocalQuery(queryValue);
    }, [queryValue]);

    useDebounce(() => {
        dispatch(setQueryAction(localQuery));
    }, 500, [localQuery]);

    const onQueryValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalQuery(event.target.value);
    };
    const onQueryCleared = () => dispatch(setQueryAction(''));
    const exampleQueries = Config.appQueries.map(appQuery => appQuery.query);

    const onExampleQueryClick = (query: string) => {
        const appQuery = Config.appQueries.find(appQuery => appQuery.query === query);
        if (appQuery) {
            dispatch(setAppQueryAction(appQuery));
        }
    }

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton
                aria-label="close"
                onClick={onQueryCleared}
                edge="end"
            >
                <Cancel/>
            </IconButton>
        </InputAdornment>
    );

    return (
        <Stack direction="row" flexGrow={ 1 } spacing={ 0 } alignItems="baseline"  mr={2} >
            <ExampleQueryButton
                queries={ exampleQueries }
                onClick={ onExampleQueryClick }
            />
            <FormControl sx={{ ml: 1,flexGrow:1 }} variant="outlined" size={'small'}>
                <InputLabel >Publications query</InputLabel>
                <OutlinedInput  sx={{borderTopRightRadius:0,borderBottomRightRadius:0 }}
                    id="outlined-adornment-query"
                    type={'text'}
                    endAdornment={endAdornment}
                    label="Publications query"
                    value={ localQuery }
                    onChange={ onQueryValueChange }
                />
            </FormControl>
            <Box  sx={{
                bgcolor: 'white',
                borderRadius: 1,
                borderTopLeftRadius:0,
                borderBottomLeftRadius:0,
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                borderLeft:'0px solid #ccc',
                borderRight: '1px solid #ccc',
                height:"37px", marginRight:"6px"}} >
                <Typography sx={{ my: 1,color: 'rgba(0, 0, 0, 0.6)', margin:'8px' }}>
                    Publications: { documentCount ?? <Skeleton variant="text" width="35px" height="1.5em" sx={{
                        display: 'inline-block'
                }} /> }
            </Typography>
            </Box>
            <Typography style={{marginRight: 4}} sx={{ my: 3, color: 'black', display: 'block',fontWeight: 'bold' }}>In</Typography>
            <SourceSelector
                selected={Array.from(selected)}
                available={Array.from(available)}
                select={(s)=> dispatch(addSourceAction(s))}
                unselect={(s) => dispatch(removeSourceAction(s))}
            />
        </Stack>
    );
};
