import {Legend, Series, Point} from "highcharts";
import {GraphEvents, PointWithOriginal} from "./Utils";

type HasData = {
    hasData: () => boolean
}

export const buildOnPointHover = (onHover: GraphEvents['onPointHover']) => {
    return function onPointHover(this: Point, _event: Event) {
        if (onHover) {
            onHover((this as PointWithOriginal).original);
        }
    }
}

export const buildOnPointClick = (onClick: GraphEvents['onPointClick']) => {
    return function onPointClick(this: Point, _event: Event) {
        if (onClick) {
            onClick((this as PointWithOriginal).original);
        }
    }
}

export function legendDoubleClick(this: Series) {
    // Possible a private method OR the type above is incorrect
    if ((this as unknown as HasData).hasData()) {
        this.chart.series.filter((s: Series) => s !== this).forEach((s: Series) => s.hide());
        this.show();
    }
}

export function legendClick(this: Highcharts.Series) {
    if (this.visible && this.hide && this.show) {
        const otherWithData = this.chart.series
            .filter((s: Series) => s !== this)
            .some((s: Series) => s.visible  && (s as unknown as HasData).hasData());
        if (otherWithData) {
            this.hide();
        } else {
            this.show();
            // Workaround to prevent the bubbleLegend from being hidden:
            const legend = this.chart.legend as Legend & { bubbleLegend: { visible: boolean }};
            legend.update({ bubbleLegend: { enabled: true }});
            legend.bubbleLegend.visible = true;
        }
    } else if (this.show) {
        this.show();
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function preventDefault(e: { preventDefault: Function }) {
    e.preventDefault();
}
