import {useQuery} from "react-query";
import axios from "axios";
import {PaperCountRequest, PaperCountResponse} from "../types/PapersCount";
import {useStoreDispatch} from "../store/store";
import {setDocumentCount} from "../store/actions/setDocumentCount";

/**
 * This is an implementation of the first version of the API to get the paper count
 * The response is modified to match the `PaperCountResponse`.
 * @param request
 */
export const usePapersCount = (request?: PaperCountRequest) => {
    const dispatch = useStoreDispatch()

    const sources = [...request?.sources ?? []].join(',');
    const { isLoading, error, data } = useQuery({
        queryKey: [ 'papers-count', request?.query, sources ],
        queryFn: async (): Promise<PaperCountResponse> => {
            dispatch(setDocumentCount(undefined));
            const response = await axios.post(
                '/api/get_papers_count',
                {
                    'search-term': request?.query,
                    sources: sources
                }
            );

            dispatch(setDocumentCount(response.data['papers_count']));
            // Transform
            return {
                count: response.data['papers_count']
            };
        },
        enabled: !!request,
        retry: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    return {
        isLoading,
        error,
        data
    };
};
