import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DocumentType, ScicartaDocument} from "../../types/DocumentData";
import {PaperListInformationSorter} from "./PaperListInformationSorter";
import {useDownloadCsv} from "../../services/DownloadCsv";
import {getSelectedDocuments} from "../../store/selectors";
import {useSelector} from "react-redux";
import {useStoreDispatch} from "../../store/store";
import {documentsClearSelectedAction} from "../../store/actions/documents/documentsClearSelected";
import {selectAllDocumentsAction} from "../../store/actions/documents/selectAllDocuments";
import download from 'in-browser-download';
import {LoadingButton} from "@mui/lab";

interface PaperListSelectedInformationProps {
    documents : ReadonlyArray<ScicartaDocument>;
    onDeleteDocument: (documentId: string) => void;
}

const getTextByItem = (item: ScicartaDocument): string => {
    if (item.type === DocumentType.PUBMED) {
        let paper = (item.authors) ? item.authors.map((text) => text ).join(', ') : 'Authors not found';
        paper += '. ';
        paper += item.title;
        paper += ' ';
        paper += item.journal;
        paper += `. PubMed PMID: ${ item.id } `;
        paper += item.doi_href;
        paper += "\n";

        return paper.replace(/<[^>]+>/g, '');
    }
    if (item.type === DocumentType.CLINICAL_TRIAL) {
        let paper = (item.sponsor) ? item.sponsor.map((text) => text ).join(', ') : 'Authors not found';
        paper += '. ';
        paper += item.brief_summary;
        paper += ' ';
        paper += `. PubMed PMID: ${ item.id } `;
        paper += 'https://clinicaltrials.gov/ct2/show/' + item.id;
        paper += "\n";

        return paper.replace(/<[^>]+>/g, '');
    }
    return "";
};

export const PaperListSelectedInformation: React.FunctionComponent<PaperListSelectedInformationProps> = (props) => {
    const [ isVisibleCopiedLabel, setIsVisibleCopiedLabel ] = React.useState<boolean>(false);
    const separator = '\r\n';

    const copiedTexts = props.documents.map((document) => {
        return getTextByItem(document);
    });

    const onCopyPaper = React.useCallback((_text: string) => {
        setIsVisibleCopiedLabel(true);
        setTimeout(function () {
            setIsVisibleCopiedLabel(false);
        }, 1500);
    }, [setIsVisibleCopiedLabel]);

    const downloadCsv = useDownloadCsv();
    const selectedDocuments = useSelector(getSelectedDocuments);
    const dispatch = useStoreDispatch();

    return (
        <Box mr={2}>
            <Grid container direction="column" alignItems="flex-end" >
                <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                    {
                        isVisibleCopiedLabel && <Button variant="text" >
                                Copied
                            </Button>
                    }
                    <Button onClick={() => dispatch(selectAllDocumentsAction())} startIcon={<SelectAllIcon />} variant="outlined">
                        Select all
                    </Button>
                    <CopyToClipboard text={ copiedTexts.join(separator) } onCopy={ onCopyPaper }>
                        <Button variant="outlined" startIcon={<ContentCopyIcon />} disabled={props.documents.length === 0}>
                            Copy to clipboard
                        </Button>
                    </CopyToClipboard>
                    <LoadingButton loading={downloadCsv.isLoading} loadingPosition="start" onClick={async () => {
                        const content = await downloadCsv.mutateAsync({
                            paperIds: Object.keys(selectedDocuments)
                        });
                        download(content.csv, `publications-${new Date().toISOString()}.csv`);
                    }} startIcon={<DownloadIcon />} variant="outlined" disabled={props.documents.length === 0 || downloadCsv.isLoading}>
                        <span>CSV Download</span>
                    </LoadingButton>
                    <Button onClick={() => dispatch(documentsClearSelectedAction())} startIcon={<DeleteIcon />} disabled={props.documents.length === 0} color="error" variant="outlined">
                        Clear
                    </Button>
                </Grid>
                <PaperListInformationSorter onDeleteDocument={props.onDeleteDocument} documents={props.documents} getTextByItem={getTextByItem}/>
            </Grid>
        </Box>
    );
};
