import {style} from "typestyle";
import {AxisLabelsFormatterContextObject, BubbleLegendFormatterContextObject, PointLabelObject} from "highcharts";
import {PointWithOriginal} from "./Utils";
import {abbreviate} from "../../utils/Number";

const impactNotFoundLabel = style({
    textAlign: 'center'
})

// This is not part of the documented API
type AxisPrivate = {
    paddedTicks: Array<number>
};

export function labelFormatter(this: AxisLabelsFormatterContextObject) {
    if (this.value === 1) {
        return 'Highest';
    } else if (this.value === 0) {
        return 'Lowest';
    }

    // On the value after 0, show the "Impact not found" legend
    const paddedTicks = (this.axis as unknown as AxisPrivate).paddedTicks;

    const index = paddedTicks.indexOf(this.value as number);
    if (index + 1 < paddedTicks.length && paddedTicks[index + 1] === 0) {
        return `<div class="${ impactNotFoundLabel }"></br>Not Available</div>`;
    }

    if (index === paddedTicks.length - 2 && paddedTicks[paddedTicks.length - 1] < 1) {
        return 'Highest';
    }

    return '';
}

export const buildBubbleLegendFormatter = (minSize: number, maxSize: number) => {
    return function bubbleLegendFormatter(this: BubbleLegendFormatterContextObject) {
        if (this.value === maxSize) {
            return `Most citations (${abbreviate(maxSize)})`;
        }

        return `Least citations (${abbreviate(minSize)})`;
    }
};

export function dataLabelFormatter(this: PointLabelObject) {
    const basePoint = (this.point as PointWithOriginal).original;
    return basePoint.content;
}

export const getLegend = () => {
    const styleTitle = 'style="font-size: 11px; color: #000"';
    const styleEntry = 'style="font-size: 10px; color: #000; font-weight: normal"';
    return `<span ${styleTitle}>▣&nbsp;bioR<i>x</i>iv</span> <br/> <br/>\
            <span ${styleTitle}>♢&nbsp;Clinical trials</span> <br/> \
            <span ${styleEntry}>○&nbsp;starting&nbsp;→&nbsp;ongoing&nbsp;﹖&nbsp;unknown</span>    \
            <span ${styleEntry}>x&nbsp;aborted&nbsp;✓&nbsp;completed&nbsp;★&nbsp;approved</span>    \
            <span ${styleTitle}>◯ Pubmed</span>`;
};
