import {StoreActionReducer} from "../../reducer";
import {StoreActionType, StoreState} from "../../store";

export interface StoreActionDocumentsSelectAll {
    type: StoreActionType.DOCUMENTS_SELECT_ALL;
}

export const selectAllDocumentsAction = (): StoreActionDocumentsSelectAll => ({
    type: StoreActionType.DOCUMENTS_SELECT_ALL
});

export const selectAllDocumentsReducer: StoreActionReducer<StoreActionType.DOCUMENTS_SELECT_ALL> = (state, _action): StoreState | void  => {
    state.documentsStore.selectedDocuments = state.documentsStore.documents;
}
