import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";
import {produce} from "immer";

export interface StoreActionAddSource {
    type: StoreActionType.ADD_SOURCE;
    source: string;
}

export const addSourceAction = (source: string): StoreActionAddSource => ({
    type: StoreActionType.ADD_SOURCE,
    source
});

export const addSourceReducer: StoreActionReducer<StoreActionType.ADD_SOURCE> = (state, action): StoreState | void => {
    state.selectedSources = produce(state.selectedSources, (draft => {
        draft.add(action.source);
    }));
};
