import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ModalDialogTitle} from "./ModalDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Link} from "@mui/material";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));

interface HelpProps {
    isOpen : boolean;
    handleClose: () => void;
}
export const Help: React.FunctionComponent<HelpProps> = (props) => {
    return (
        <div>
            <ModalDialog
                fullWidth={true}
                maxWidth={'lg'}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
                scroll={"paper"}
            >
                <ModalDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Getting started
                </ModalDialogTitle>
                <DialogContent dividers>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >
                        <Typography paragraph={true}>
                            <ol>
                                <li >Click on PubMed query and pull down to covid-19. This gets all the covid papers from PubMed.</li>
                                <li >Edit the Search Terms box if you want to search any more keywords separated by commas. It finds from the covid-19 corpus the papers that mention the entered keywords.</li>
                                <li >In the little video above you'll see that I add pregnancy as another Search Term. It shows up as a new item in the legend. I click on pregnancy to then see only those papers that relate to pregnancy and covid-19.</li>
                                <li >Click on the legend to toggle visibility of specific keywords.</li>
                                <li >Zoom in by drawing a box around the dots with your mouse. This can be a little tricky but is easy once you get it.</li>
                                <li >You can also change the PubMed Query term to create a different corpus.</li>
                                <li >Currently it will pull down only the first 20k matching papers it finds from PubMed to your query term.</li>
                                <li >Note that you can also search for compound keywords, for example "neutralizing antibodies, vaccine, chloroquine, case fatality rate"</li>
                            </ol>
                        </Typography>

                            <Box display="flex">
                                <Box
                                    m="auto"
                                    component="img"
                                    alt="covid pregnancy animated"
                                    src="https://app.scicarta.com/covid_pregnancy_animiated_3.gif"
                                >
                                </Box>
                            </Box>
                        <Typography paragraph={true}>
                            <Typography variant="h6" align={"left"}>
                                <b>What do the dots mean?</b>
                            </Typography>
                            <ol >
                                <li>A dot represents a paper.</li>
                                <li>The color of the dot depends on the keywords found in the paper. If more than 1 keyword is found the dot will be the combined color of the colors.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>What does the placement of the dot mean?</b>
                            </Typography>
                            <ol>
                                <li>Y-axis is the normalized impact factor of the journal</li>
                                <li>Size of dot relates to number of citations.</li>
                                <li>Of course most of the recent covid-19 papers are so new that they haven't yet been cited.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>How do I read an abstract of a paper?</b>
                            </Typography>
                            <ol>
                                <li>Hover over a dot and it will show up in the lower left pane.</li>
                                <li>Click on the dot and it will be fixed in the lower right pane.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>How do you I get full text?</b>
                            </Typography>
                            <ol>
                                <li>Click on the title of the papers in the lower panes to go to the journal sites.</li>
                                <li>Most covid-19 papers are full-text.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>How can I share papers I find?</b>
                            </Typography>
                            <ol>
                                <li>Click on a dot to add it to the Selected Publications List in the lower right pane.</li>
                                <li>From there you can copy a single paper or all the papers.</li>
                                <li>Paste in your email and send.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>When does it pull new papers?</b>
                            </Typography>
                            <ol>
                                <li>Every day its database updates with the latest papers from PubMed.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>How do I analyze a plot?</b>
                            </Typography>
                            <ol >
                                <li>This is an open-ended tool for you to interpret the plot with your expertise.</li>
                                <li>Interesting questions it can address may include:
                                    <Typography paragraph={true}>
                                    <ul >
                                        <li>what's the arc of research on a topic</li>
                                        <li>what are the earlypapers of note (e.g., in 'herd immunity' you'll see a 1973 NEJM paper that shows it didn't work for the rubella outbreak)</li>
                                        <li>what papers are notable on a topic (based on keyword / impact factor of journal / number of citations)</li>
                                    </ul>
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>How do I share a ‘carta’?</b>
                            </Typography>
                            <ol>
                                <li>Click on the share button, copy the link and email it to anyone.</li>
                                <li>The recipient will be able to view the main search and keywords.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>What searches can I do?</b>
                            </Typography>
                            <ol>
                                <li>You can search any topic as you would in PubMed. Only the results from the first 20k papers will be shown.</li>
                            </ol>
                        </Typography>
                        <Typography paragraph={true}>
                            <Typography variant="h6" >
                                <b>Does this contain data other than PubMed?</b>
                            </Typography>
                            <ol>
                                <li>Not yet. Email <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link> to tell us what other datasets or features you’d like</li>
                            </ol>
                        </Typography>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </div>
    );
};
