import {useMutation} from "react-query";
import axios from "axios";

interface DownloadCsvRequest {
    paperIds: Array<string>;
}

interface DownloadCsvResponse {
    csv: string;
}

export const useDownloadCsv = () => {
    return useMutation({
        mutationKey: ['download-csv'],
        mutationFn: async (request: DownloadCsvRequest): Promise<DownloadCsvResponse> => {
            const response = await axios.post(
                '/api/download-csv',
                request.paperIds
            )
            return {
                csv: response.data
            };
        }
    });
}
