import * as React from "react";
import { Grid, Typography, Button } from "@mui/material";
import {Link} from "react-router-dom";


export const Footer: React.FunctionComponent = () => {
    return (
        <Grid container
              //position="fixed"
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                  height:"100%",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  bgcolor: "#E0E0E0"}}>
            <Grid item xs={4}/>
            <Grid item xs={4} justifyContent="center" alignItems="center">
                <Typography variant="h6">© Scicarta Inc, 2021</Typography>
            </Grid>
            <Grid item xs={4} justifyContent="flex-end" >
                <Button component={Link} color="inherit" to="contact-us" >Contact Us</Button>
                <Button component={Link} color="inherit" to="privacy-police">Privacy Police</Button>
                <Button component={Link} color="inherit" to="terms-of-use">Terms of Use</Button>
            </Grid>
        </Grid>
    );
};
