import * as React from 'react';
import { Typography} from '@mui/material';

interface PaperListInformationDetailProps {
    title: string;
    content: string;
}

export const PaperListInformationDetail: React.FunctionComponent<PaperListInformationDetailProps> = (props) => {
    return (
        <Typography align={"justify"} component="div"  variant="caption">
            <Typography variant="caption" component="strong" sx={{ color:"#555"}}>
                {props.title}
            </Typography>
            {!!props.title && !!props.content && '-'}
            <Typography variant="caption" component="span">{props.content} </Typography>
        </Typography>
    );
};

