import {AppQuery, StoredFilterElement} from "../../../types/AppQuery";
import {StyledWords} from "../../highlighter/HighLightReconciliation";
import {useMemo} from "react";
import {ColorPalette} from "../../../utils/Color";

export const useStyledWords = (appQuery: AppQuery<StoredFilterElement>): ReadonlyArray<StyledWords> => {
    return useMemo<ReadonlyArray<StyledWords>>(() => {
        const words = appQuery.query.split(" ").map(s => s.trim()).filter(s => s !== '');

        const filters: ReadonlyArray<StyledWords> = appQuery.filters.map(f => ({
            searchWords: [f.displayName, f.id],
            style: {
                color: [ColorPalette.getColor(f.color).pastel.toString()]
            }
        }));

        return [
            {
                style: {
                    bold: true
                },
                searchWords: words
            },
            ...filters
        ];
    }, [appQuery.query, appQuery.filters]);
};
