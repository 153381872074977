import {StoreActionType, StoreState} from "../../store";
import {TreeFilter} from "../../../types/TreeFilter";
import {StoreActionReducer} from "../../reducer";

export interface StoreActionSetTreeFilter {
    type: StoreActionType.SET_TREE_FILTER;
    treeFilter: TreeFilter;
}

export const setTreeFilterAction = (treeFilter: TreeFilter): StoreActionSetTreeFilter => ({
    type: StoreActionType.SET_TREE_FILTER,
    treeFilter: treeFilter
});

export const setTreeFilterReducer: StoreActionReducer<StoreActionType.SET_TREE_FILTER> = (state, action): StoreState | void => {
    state.treeFilter = action.treeFilter;
};
