import * as React from 'react';
import {PaperListInformation} from "../PaperListInformation";
import {PaperListInformationDetail} from "../PaperListInformationDetail";
import {DocumentPubmed, ScicartaDocument} from "../../../types/DocumentData";

interface PaperSelectedInformationPubMedProps {
    document : DocumentPubmed;
    getTextByItem: (item: ScicartaDocument)=>string;
    onDeleteDocument: (documentId: string) => void;
}

export const PaperSelectedInformationPubMed: React.FunctionComponent<PaperSelectedInformationPubMedProps> = (props) => {

    return (
        <>
            <PaperListInformation
                title={props.document.title}
                link={props.document.doi_href}
                textToCopy={props.getTextByItem(props.document)}
                onDeleteDocument={props.onDeleteDocument}
                documentId={props.document.id}
            >
                <PaperListInformationDetail
                    title={props.document.authors.map((text) => text ).join(', ')}
                    content={""}/>
            </PaperListInformation>
        </>
    );
};
