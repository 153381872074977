import {setQueryReducer, StoreActionSetQuery} from "./actions/setQuery";
import {clearFiltersReducer, StoreActionClearFilters} from "./actions/clearFilters";
import {addFilterReducer, StoreActionAddFilter} from "./actions/addFilter";
import {removeFilterReducer, StoreActionRemoveFilter} from "./actions/removeFilter";
import {StoreActionSetAvailableSources} from "./actions/setAvailableSources";
import {createReducer, StoreActionHandlers} from "./reducer";
import {configureStore, isPlain} from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import {Dispatch} from "redux";
import {setAppQueryReducer, StoreActionSetAppQuery} from "./actions/setAppQuery";
import {FilterElementType, StoredFilterElement} from "../types/AppQuery";
import Config from "../Config";
import {Entity} from "../types/Entities";
import {addEntitiesReducer, StoreActionAddEntities} from "./actions/entities/addEntity";
import {removeEntitiesReducer, StoreActionRemoveEntities} from "./actions/entities/removeEntity";
import {TreeFilter} from "../types/TreeFilter";
import {setTreeFilterReducer, StoreActionSetTreeFilter} from "./actions/treeFilter/setTreeFilter";
import {setLastHoveredReducer, StoreActionSetLastHovered} from "./actions/graph/setLastHovered";
import {GraphStatus} from "../types/GraphStatus";
import {documentAddDocumentsReducer, StoreActionAddDocuments} from "./actions/documents/addDocuments";
import {DocumentStore} from "../types/DocumentStore";
import {setLastClickedReducer, StoreActionSetLastClicked} from "./actions/graph/setLastClicked";
import {documentSelectedDocumentReducer} from "./actions/documents/addSelectedDocument";
import {
    documentRemoveSelectedDocumentsReducer,
    StoreActionRemoveSelectedDocument
} from "./actions/documents/removeSelectedDocument";
import {addSourceReducer, StoreActionAddSource} from "./actions/addSource";
import {removeSourceReducer, StoreActionRemoveSource} from "./actions/removeSource";
import {UserInfo} from "../types/UserInfo";
import {produce} from "immer";
import {setDocumentCountReducer, StoreActionSetDocumentCount} from "./actions/setDocumentCount";
import {selectAllDocumentsReducer, StoreActionDocumentsSelectAll} from "./actions/documents/selectAllDocuments";
import {
    documentsClearSelectedReducer,
    StoreActionDocumentsClearSelected
} from "./actions/documents/documentsClearSelected";

export interface StoreState {
    readonly query: string;
    readonly filters: Readonly<Record<string, StoredFilterElement>>;
    readonly availableSources: ReadonlySet<string>;
    readonly selectedSources: ReadonlySet<string>;
    readonly entities: Readonly<Record<string, Readonly<Entity>>>;
    readonly treeFilter: TreeFilter;
    readonly graphStatus: GraphStatus;
    readonly documentsStore: Readonly<DocumentStore>;
    readonly documentCount: number | undefined;
}

export interface StoreActionPrototype<T extends StoreActionType> {
    type: T;
}

// Take initial state from config
const initialQuery = Config.appQueries[0].query;
const filters = [...Config.appQueries[0].filters.values()];

const initialFilters = Object.fromEntries(
    filters.map((value, index) => ([value.id, {...value, color: index}]))
);

const entities = Object.fromEntries(
    filters.filter(f => f.type === FilterElementType.ENTITY).map(f => ([f.id, {...f}]))
);

export const initialState: StoreState = {
    query: initialQuery,
    filters: initialFilters,
    availableSources: new Set([ 'pubmed', 'clinical-trials' ]),
    selectedSources: new Set([ 'pubmed' ]),
    entities: entities,
    treeFilter: {
        content: []
    },
    graphStatus: {
        lastHoveredPoint: undefined,
        lastClickedPoint: undefined
    },
    documentsStore: {
        documents: {},
        selectedDocuments: {}
    },
    documentCount: undefined
};

// Try to keep the StoreAction and StoreActionType order in sync
export type StoreAction =
    // Sources
    StoreActionSetAvailableSources |
    StoreActionAddSource |
    StoreActionRemoveSource |

    // Entities
    StoreActionAddEntities |
    StoreActionRemoveEntities |

    // Tree filter
    StoreActionSetTreeFilter |

    // Graph
    StoreActionSetLastHovered |
    StoreActionSetLastClicked |

    // Documents
    StoreActionAddDocuments |
    StoreActionRemoveSelectedDocument |
    StoreActionSetDocumentCount |
    StoreActionDocumentsClearSelected |
    StoreActionDocumentsSelectAll |

    StoreActionSetQuery |
    StoreActionSetAppQuery |
    StoreActionAddFilter |
    StoreActionRemoveFilter |
    StoreActionClearFilters;

export enum StoreActionType {
    // Sources
    SET_AVAILABLE_SOURCES = 'SET_AVAILABLE_SOURCES',
    ADD_SOURCE = 'ADD_SOURCE',
    REMOVE_SOURCE = 'REMOVE_SOURCE',

    // entities
    ADD_ENTITIES = 'ADD_ENTITIES',
    REMOVE_ENTITIES = 'REMOVE_ENTITIES',

    // entity navigation
    SET_TREE_FILTER = 'SET_TREE_FILTER',

    // Query
    SET_QUERY = 'SET_QUERY',
    SET_APP_QUERY = 'SET_APP_QUERY',

    // Filters (keywords, entities)
    ADD_FILTER = 'ADD_FILTER',
    REMOVE_FILTER = 'REMOVE_FILTER',
    CLEAR_FILTERS = 'CLEAR_FILTERS',

    // Graph
    SET_LAST_HOVERED = 'SET_LAST_HOVERED',
    SET_LAST_CLICKED = 'SET_LAST_CLICKED',

    // Documents
    ADD_DOCUMENTS = 'documents/add_documents',
    REMOVE_SELECTED_DOCUMENT = 'documents/remove-selected-document',
    SET_DOCUMENT_COUNT = 'documents/set-count',
    DOCUMENTS_SELECT_ALL = 'documents/select_all',
    DOCUMENTS_CLEAR_SELECTED = 'documents/clear'
}

const getReducer = () => {
    const handlers: StoreActionHandlers = {
        // sources
        [StoreActionType.ADD_SOURCE]: addSourceReducer,
        [StoreActionType.REMOVE_SOURCE]: removeSourceReducer,

        // entities
        [StoreActionType.ADD_ENTITIES]: addEntitiesReducer,
        [StoreActionType.REMOVE_ENTITIES]: removeEntitiesReducer,

        // Entity navigation
        [StoreActionType.SET_TREE_FILTER]: setTreeFilterReducer,

        // Query
        [StoreActionType.SET_QUERY]: setQueryReducer,
        [StoreActionType.SET_APP_QUERY]: setAppQueryReducer,

        // Filters
        [StoreActionType.ADD_FILTER]: addFilterReducer,
        [StoreActionType.REMOVE_FILTER]: removeFilterReducer,
        [StoreActionType.CLEAR_FILTERS]: clearFiltersReducer,

        // Graph
        [StoreActionType.SET_LAST_HOVERED]: setLastHoveredReducer,
        [StoreActionType.SET_LAST_CLICKED]: [
            setLastClickedReducer,
            documentSelectedDocumentReducer
        ],

        // Documents
        [StoreActionType.ADD_DOCUMENTS]: documentAddDocumentsReducer,
        [StoreActionType.REMOVE_SELECTED_DOCUMENT]: documentRemoveSelectedDocumentsReducer,
        [StoreActionType.SET_DOCUMENT_COUNT]: setDocumentCountReducer,
        [StoreActionType.DOCUMENTS_SELECT_ALL]: selectAllDocumentsReducer,
        [StoreActionType.DOCUMENTS_CLEAR_SELECTED]: documentsClearSelectedReducer
    };

    return createReducer(handlers);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSerializable = (value: any) =>  isPlain(value) || value.entries;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEntries = (value: any) => value.entries ? value.entries() : Object.entries(value)

export const getReduxStore = (userInfo: UserInfo) => {
    return configureStore({
        reducer: getReducer(),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: produce(initialState, draft => {
            draft.availableSources = new Set(userInfo.scicarta.source);
            draft.selectedSources = new Set(userInfo.scicarta.selected_source);
        }),
        middleware: (c) => c({
            serializableCheck: {
                isSerializable,
                getEntries
            }
        })
    });
}

export const useStoreDispatch = useDispatch<Dispatch<StoreAction>>;
