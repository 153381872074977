import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionSetDocumentCount {
    type: StoreActionType.SET_DOCUMENT_COUNT;
    count: number | undefined;
}

export const setDocumentCount = (count: number | undefined): StoreActionSetDocumentCount => ({
    type: StoreActionType.SET_DOCUMENT_COUNT,
    count
});

export const setDocumentCountReducer: StoreActionReducer<StoreActionType.SET_DOCUMENT_COUNT> = (state, action): StoreState | void => {
    state.documentCount = action.count;
};
