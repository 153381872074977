import * as React from 'react';
import {PaperViewerDocumentPubmed} from "./paperViewerTypes/PaperViewerDocumentPubmed";
import {PaperViewerDocumentClinicalTrial} from "./paperViewerTypes/PaperViewerDocumentClinicalTrial";
import {DocumentType, ScicartaDocument} from "../../types/DocumentData";
import {PaperViewerDocumentLoading} from "./paperViewerTypes/PaperViewerDocumentLoading";
import assertNever from "assert-never";
import {PaperViewerDocumentProps} from "./paperViewerTypes/PaperViewerDocumentProps";

export const PaperViewer: React.FunctionComponent<PaperViewerDocumentProps<ScicartaDocument>> = (props) => {
    if (props.document) {
        switch (props.document.type) {
            case DocumentType.LOADING:
                return <PaperViewerDocumentLoading {...props} document={props.document} />
            case DocumentType.PUBMED:
                return  <PaperViewerDocumentPubmed {...props} document={props.document}/>
            case DocumentType.CLINICAL_TRIAL:
                return  <PaperViewerDocumentClinicalTrial {...props} document={props.document}/>
            default:
                assertNever(props.document);
        }
    }

    return null;
};
