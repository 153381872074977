import {ScicartaDocument} from "../../../types/DocumentData";
import {StyledChunks} from "../../highlighter/HighLightReconciliation";
import {ColorPalette} from "../../../utils/Color";
import {useMemo} from "react";
import {FilterElementType, StoredFilterElement} from "../../../types/AppQuery";

export const useStyledChunks = (highlighting: ScicartaDocument['highlighting'], filters: ReadonlyArray<StoredFilterElement>): Record<string, ReadonlyArray<StyledChunks>> => {

    return useMemo(() => {
        const entities = Object.fromEntries<StoredFilterElement>(
            filters
                .filter(f => f.type === FilterElementType.ENTITY)
                .map(f => [f.id, f])
        );

        return Object.fromEntries(Object.entries(highlighting ?? {})
            .map(([property, section]) => {
                const chunks: Array<StyledChunks> = [];
                if (section) {
                    if (section.entities) {
                        chunks.push(...Object.entries(section.entities)
                            .filter(entry => Object.keys(entities).includes(entry[0]))
                            .flatMap(entry => entry[1].map(data => ({
                                style: {
                                    color: [ColorPalette.getColor(entities[entry[0]].color).pastel.toString()]
                                },
                                start: data.start,
                                end: data.end
                            }))));

                    }
                }

                return [property, chunks];
            }));
    }, [filters, highlighting]);
}
