import * as React from "react";

interface SelectedMenuItemProps {
    selected: boolean;
}

export const SelectedMenuItem: React.FunctionComponent<React.PropsWithChildren<SelectedMenuItemProps>> = props => {
    if (props.selected) {
        return <b>{props.children}</b>;
    }

    return <span>{props.children}</span>;
}
