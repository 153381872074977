import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionSetQuery {
    type: StoreActionType.SET_QUERY;
    query: string;
}

export const setQueryAction = (query: string): StoreActionSetQuery => ({
    type: StoreActionType.SET_QUERY,
    query
});

export const setQueryReducer: StoreActionReducer<StoreActionType.SET_QUERY> = (state, action): StoreState | void => {
    state.query = action.query;
};
