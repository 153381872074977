import {PlotData, PlotDataResponse, Point} from "../../types/PlotData";
import {StoredFilterElement} from "../../types/AppQuery";
import {ColorPalette} from "../../utils/Color";
import Highcharts, {SeriesOptionsType} from "highcharts";
import {buildBubbleLegendFormatter, dataLabelFormatter, getLegend, labelFormatter} from "./Formatters";
import {buildOnPointClick, buildOnPointHover, legendClick, legendDoubleClick, preventDefault} from "./Handlers";
import {style} from "typestyle";
import {ScicartaDocument} from "../../types/DocumentData";

type HighChartsCustomEvents = {
    legend: {
        itemEvents: {
            dblclick: (params: Array<unknown>) => unknown,
            click: (params: Array<unknown>) => unknown
        }
    }
}

export type HighChartsOptions = Highcharts.Options & HighChartsCustomEvents;
export type PointWithOriginal = Highcharts.Point & {
    original: Point
};

export type GraphEvents = {
    onPointHover?: (point: Point) => void;
    onPointClick?: (point: Point) => void;
}

const bubbleLegendSize = {
    max: 30,
    min: 15
};

const selectedClassName = style({
    stroke: 'black'
});

const unselectedClassName = style({
    stroke: 'none'
});

const colorForKeyword = (label: string, elements: Array<StoredFilterElement>) => {
    const element = elements.find(value =>  value.id === label);
    return element?.color ?? 0;
};

const getSeries = (data: Array<PlotData>, elements: Array<StoredFilterElement>, events: GraphEvents,selectedDocuments:ReadonlyArray<ScicartaDocument>): HighChartsOptions['series'] =>
    data.map((d): SeriesOptionsType => {
        return {
            data: d.points.map(p => ({
                x: new Date(p.x).getTime(),
                y: p.y,
                z: p.size,
                original: p,
                marker: {
                    symbol: p.shape
                },
                className: selectedDocuments.findIndex(s =>s.id === p.document_id ) !== -1 ? selectedClassName : unselectedClassName,
            })),
            color: ColorPalette.getColor(
                colorForKeyword(d.id, elements)
            ).accent.toStringColorWithAlpha(1.0),
            opacity: 0.4,
            type: 'bubble',
            states: {
                inactive: {
                    opacity: 0,
                    enabled: false
                },
                hover: {
                    halo: {
                        size: 1
                    }
                }
            },
            name: d.name,
            events: {
                legendItemClick: preventDefault
            },
            point: {
                events: {
                    click: buildOnPointClick(events.onPointClick),
                    mouseOver: buildOnPointHover(events.onPointHover)
                }
            }
            // Todo: Match the order they are specified
            // legendIndex:
        }
    });

const getCitationsSummary = (plotData: PlotDataResponse): [number, number] => {
    return plotData.data.reduce((prev, current) => {
        const sizes = current.points.map(p => p.size);
        return [
            Math.min(prev[0], Math.min(...sizes)),
            Math.max(prev[1], Math.max(...sizes))
        ];
    }, [Infinity, -Infinity] as [number, number]);
}

export const buildOptions = (plotData: PlotDataResponse | undefined, filters: Array<StoredFilterElement>, events: GraphEvents, selectedDocuments:ReadonlyArray<ScicartaDocument>): HighChartsOptions | undefined => {
    if (plotData) {
        const [minSize, maxSize] = getCitationsSummary(plotData);
        const series = getSeries(plotData.data, filters, events, selectedDocuments);

        return {
            series,
            accessibility: {
                enabled: false
            },
            title: {
                text: plotData.title
            },
            chart: {
                shadow: false,
                zooming: {
                    type: 'xy'
                },
                panning: {
                    enabled: true,
                    type: 'xy'
                },
                panKey: 'shift',
                animation: false
            },
            xAxis: {
                type: 'datetime' // TODO: Make it configurable
            },
            yAxis: {
                title: {
                    text: plotData.y_axis.title
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: '#ccd6eb'
                    }
                ],
                lineWidth: 1,
                gridLineColor: 'transparent',
                tickInterval: 0.1,
                labels: {
                    useHTML: true,
                    formatter: labelFormatter,
                }
            },
            legend: {
                enabled: true,
                width: '170px',
                itemEvents: {
                    dblclick : legendDoubleClick,
                    click: legendClick
                },
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                title: {
                    text: (series && series.length > 0) ? getLegend() : ''
                },
                itemMarginTop: 4,
                bubbleLegend: {
                    maxSize: bubbleLegendSize.max,
                    minSize: bubbleLegendSize.min,
                    enabled: true,
                    borderColor: '#000000',
                    color: '#ffffff',
                    labels: {
                        formatter: buildBubbleLegendFormatter(minSize, maxSize),
                    },
                    ranges: [minSize, maxSize].map(r => ({
                        value: r
                    }))
                }
            },
            plotOptions: {
                bubble: {
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{point.original.legend}'
                    }
                },
                series: {
                    dataLabels: {
                        animation: false,
                        enabled: true,
                        inside: true,
                        style: {
                            fontSize: '10px',
                            textOutline: '1px',
                            color: 'black'
                        },
                        formatter: dataLabelFormatter,
                    }
                }
            },
            tooltip: {
                shared: true,
                backgroundColor: '#ddd',
                followPointer: false,
                shadow: false,
                animation: false,
                shape: 'square'
            }
        };
    }

    return undefined;
};
