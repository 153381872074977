import React, {FunctionComponent, useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle, IconButton, InputAdornment, TextField} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from 'react-copy-to-clipboard';
import DialogContent from "@mui/material/DialogContent";

export interface ShareDialogProps {
    link: string;
    isOpen: boolean;
    handleClose: () => void;
}

export const ShareDialog: FunctionComponent<ShareDialogProps> = ({link, isOpen, handleClose}) => {
    const [isCopied, setCopy] = useState(false);
    const onCopied = () => setCopy(true);

    return <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>Share query link</DialogTitle>
        <DialogContent dividers>
            <CopyToClipboard text={link} onCopy={onCopied} options={ { format: 'text/plain' } }>
                <TextField
                    style={{ display: 'flex' }}
                    variant="filled"
                    helperText={isCopied ? 'Link copied to clipboard' : undefined}
                    label="Click to copy"
                    InputProps={{
                        value: link,
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="Copy query link"
                                edge="end"
                            >
                                <ContentCopyIcon/>
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </CopyToClipboard>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </Dialog>;
}
