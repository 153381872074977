import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import ShareIcon from '@mui/icons-material/Share';
import {style} from 'typestyle';
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {ModalDialogTitle} from "./ModalDialogTitle";
import DialogContent from "@mui/material/DialogContent";


const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));


const listClass = style({
    listStyle: 'none',
    margin:'0',
    padding:'0',
    marginBottom:'20px'
});

interface QuickStartProps {
    isOpen : boolean;
    handleClose: () => void;
}
    export const QuickStart: React.FunctionComponent<QuickStartProps> = (props) => {

    return (
        <div>
            <ModalDialog
                fullWidth={true}
                maxWidth={'lg'}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <ModalDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Quickstart
                </ModalDialogTitle>
                <DialogContent dividers>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >
                        <ol >
                            <li>
                                <Typography display="flex" alignItems="end" >
                                    <InsertDriveFileIcon /><b>Select publications</b>
                                </Typography>
                                <ul className={listClass} >
                                    <li>- Enter keywords for the topic you want to research</li>
                                    <li >- Directly type keywords in the "Publications Query" field</li>
                                </ul>
                            </li>
                            <li>
                                <Typography display="flex" alignItems="end">
                                    <VisibilityIcon /><b>Go over concepts and abstracts</b>
                                </Typography>
                                <ul className={listClass} >
                                    <span>Refine your publication search by selecting concepts or entering textual searches</span>
                                    <li>- Drill down on concept sidebar for insights</li>
                                    <li>- Hover over plot bubbles to read publications</li>
                                </ul>
                            </li>
                            <li>
                                <Typography display="flex" alignItems="end">
                                    <SearchIcon /><b>Refine Search</b>
                                </Typography>
                                <ul className={listClass} >
                                    <li>- To select concepts on sidebar, drill-down and click interesting concepts
                                        and/or</li>
                                    <li>- To enter textual search, directly type keywords in in the "Concept/Text Keywords" field
                                        For example, vaccine, neutralizing antibodies, remdesivir.</li>
                                </ul>
                            </li>
                            <li>
                                <Typography display="flex" alignItems="end">
                                    <ReplayIcon/><b>Iterate</b>
                                </Typography>
                                <ul className={listClass} >
                                    <li>- Change the publication query or concept/text subquery</li>
                                </ul>
                            </li>
                            <li>
                                <Typography display="flex" alignItems="end">
                                    <ShareIcon/><b>Share</b>
                                </Typography>
                                <ul className={listClass} >
                                    <li>- Double click on bubble so add publications you want to share to your publication list</li>
                                    <li>- Press on the Share button to get a hard link that shares your analysis</li>
                                </ul>
                            </li>
                        </ol>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </div>
    );
};
