import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Fill,  Top } from "react-spaces";
import {ScicartaDocument} from "../types/DocumentData";
import {PaperListSelectedInformation} from "./listPaperViewer/PaperListSelectedInformation";

interface SelectedPublicationsProps {
    selectedDocuments: ReadonlyArray<ScicartaDocument>;
    onDeleteDocument: (documentId: string) => void;
}

export const SelectedPublications: React.FunctionComponent<React.PropsWithChildren<SelectedPublicationsProps>> = (props) => {
    const marginText = {
        margin:"18px"
    };
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
            <TabContext value={value}>
                <Top size="50px" order={1}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Last selected paper" value="1" />
                            <Tab label="Selected Publications" value="2" />
                        </TabList>
                    </Box>'
                </Top>
                <TabPanel value="1" >
                    <Fill style={marginText} scrollable={true}>
                        {props.children}
                    </Fill>
                </TabPanel>
                <TabPanel value="2">
                    <Fill style={marginText} scrollable={true}>
                        <PaperListSelectedInformation onDeleteDocument={props.onDeleteDocument} documents={props.selectedDocuments}/>
                    </Fill>
                </TabPanel>
            </TabContext>
    );
};
