import {ScicartaDocument} from "../../../types/DocumentData";
import {StoreActionReducer} from "../../reducer";
import {StoreActionType, StoreState} from "../../store";

export interface StoreActionAddDocuments {
    type: StoreActionType.ADD_DOCUMENTS;
    documents: ReadonlyArray<ScicartaDocument>;
}

export const documentsAddDocumentAction = (documents: ReadonlyArray<ScicartaDocument>): StoreActionAddDocuments => ({
    type: StoreActionType.ADD_DOCUMENTS,
    documents
});

export const documentAddDocumentsReducer: StoreActionReducer<StoreActionType.ADD_DOCUMENTS> = (state, action): StoreState | void => {
    // Todo: For now we only replace each time this is called - this is because we fetch all at once.
    // In a future iteration we will have a structure to count how "old" a document is - in terms of
    // "when was the last time it was used" in a particular call
    state.documentsStore.documents = Object.fromEntries(action.documents.map(d => [d.id, d]));
}
