import * as React from 'react';
import {Typography, Link, Card, CardContent, IconButton, Grid} from '@mui/material';
import {Cancel, ContentCopy} from "@mui/icons-material";
import {PropsWithChildren} from "react";
import CopyToClipboard from 'react-copy-to-clipboard';

interface PaperListInformationProps {
    title: string;
    link: string;
    textToCopy: string;
    onDeleteDocument: (documentId: string) => void;
    documentId: string;
}

export const PaperListInformation: React.FunctionComponent<PropsWithChildren<PaperListInformationProps>> = (props) => {
    const [ isVisibleCopiedLabel, setIsVisibleCopiedLabel ] = React.useState<boolean>(false);

    const handleOnDeleteDocument = () => {
        props.onDeleteDocument(props.documentId);
    };

    const onCopyPaper = React.useCallback((_text: string) => {
        setIsVisibleCopiedLabel(true);
        setTimeout(function () {
            setIsVisibleCopiedLabel(false);
        }, 1500);
    }, [setIsVisibleCopiedLabel]);

    return (
        <Card  sx={{bgcolor:"#e7f1fa",marginTop:"8px", flexGrow:"1",width:"100%"}} >
            <CardContent >
                <Grid container spacing={2}>
                    <Grid item xs={11}
                          direction="column"
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start">
                        <Typography align={"left"} component="div" variant="caption" >
                            <Link
                                href={props.link}
                                underline="hover"
                                align={"justify"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {props.title}
                            </Link>
                        </Typography>
                        {props.children}
                    </Grid>
                    <Grid item xs={1}
                          direction="column"
                          container
                          justifyContent="flex-start"
                          alignItems="center">
                        <IconButton
                            aria-label="delete"
                            onClick={handleOnDeleteDocument}
                            edge="end"
                        >
                            <Cancel sx={{ height: 15, width: 15 }}/>
                        </IconButton>
                        <CopyToClipboard text={ props.textToCopy } onCopy={ onCopyPaper }>
                            <IconButton aria-label="copy tex"
                                        edge="end">
                                <ContentCopy sx={{ height: 15, width: 15 }} />
                            </IconButton>
                        </CopyToClipboard>
                        { isVisibleCopiedLabel && <Typography variant="caption" component="strong" sx={{fontSize:10,color:"#555"}}>
                            Copied
                        </Typography> }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>


    );
};
