import {initialState, StoreAction, StoreActionPrototype, StoreActionType, StoreState} from "./store";
import {Draft, produce} from 'immer';

export type StoreActionReducer<T extends StoreActionType> = (state: Draft<StoreState>, action: Extract<StoreAction, StoreActionPrototype<T>>) => void

export type StoreActionHandlers = {
    [type in StoreActionType]?: StoreActionReducer<type> | Array<StoreActionReducer<type>>;
}

export const createReducer = (handlers: StoreActionHandlers) => {
    return (state: StoreState = initialState, action: StoreAction) => produce(state, draft => {
        const reducers = alwaysArray(handlers[action.type]);
        if (reducers) {
            // This cast is safe, we ensure in the handlers that the action is correlated to the reducer.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            reducers.forEach(reducer => reducer(draft, action as any));
        }
    });
}

const alwaysArray = <T>(maybeArray: T | Array<T> | undefined): Array<T> => {
    if (!maybeArray) {
        return [];
    }

    if (Array.isArray(maybeArray)) {
        return maybeArray;
    }

    return [maybeArray];
};
