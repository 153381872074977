import { findChunks, combineChunks, Chunk } from 'highlight-words-core';

export interface Style {
    bold?: boolean;
    color?: Array<string>;
}

export interface StyledChunks {
    start: number;
    end: number;
    style?: Style;
}

export interface StyledWords {
    style: Style;
    searchWords: ReadonlyArray<string>;
}

interface ChunkNode {
    chunk: StyledChunks;
    prev?: ChunkNode;
    next?: ChunkNode;
}

//Copied from original library to avoid map an remap again
const fillInChunks = (chunksToHighlight: StyledChunks[], totalLength: number): StyledChunks[] => {
    const allChunks: StyledChunks[] = [];
    const append = (start: number, end: number, style?: Style) => {
        if (end - start > 0) {
            allChunks.push({
                start,
                end,
                style
            });
        }
    };

    if (chunksToHighlight.length === 0) {
        append(0, totalLength, undefined);
    } else {
        let lastIndex = 0;
        chunksToHighlight.forEach((chunk) => {
            append(lastIndex, chunk.start);
            append(chunk.start, chunk.end, chunk.style);
            lastIndex = chunk.end;
        });
        append(lastIndex, totalLength);
    }

    return allChunks;
};

const mergeStyles = (styleA: Style | undefined, styleB: Style | undefined): Style | undefined => {
    if (styleA !== undefined && styleB !== undefined) {
        const color: Array<string> = [];
        if (styleA.color) {
            color.push(...styleA.color);
        }

        if (styleB.color) {
            color.push(...styleB.color);
        }

        const bold =  !!(styleA.bold || styleB.bold);

        return color ? {
            bold,
            color
        } : { bold } ;
    }

    // if some of them is undefined, return the one that is not undefined
    // or undefined if both are.
    return styleA ? styleA : styleB;
};

const findAllWithoutFill = (searchWords: ReadonlyArray<string>, textToHighlight: string): Chunk[] => {
    return combineChunks({
        chunks: findChunks({
            autoEscape: true,
            caseSensitive: false,
            searchWords: [ ...searchWords ],
            textToHighlight
        })
    });
};

const insertInOrder = (node: ChunkNode | undefined, insertNode: ChunkNode) => {
    let current: ChunkNode | undefined = node;
    while (current && current.next && insertNode.chunk.start < current.chunk.start) {
        current = current.next;
    }

    if (current) {
        const next = current.next;
        current.next = insertNode;
        insertNode.prev = current;
        insertNode.next = next;
        if (next) {
            next.prev = insertNode;
        }
    }
};

export const highLightChunks = (textToHighlight: string, tags: ReadonlyArray<Readonly<StyledWords>>, initialStyledChunks?: ReadonlyArray<Readonly<StyledChunks>>): ReadonlyArray<Readonly<StyledChunks>> => {
    const styledChunks: StyledChunks[] = initialStyledChunks ? initialStyledChunks.map(chunk => ({...chunk, style: {...chunk.style}})) : [];
    let first: ChunkNode |  undefined;

    tags.forEach(styledWords => {
        const searchWords = styledWords.searchWords;
        const chunks = findAllWithoutFill(searchWords, textToHighlight);
        const styled = chunks.map(chunk => ({
            start: chunk.start,
            end: chunk.end,
            style: styledWords.style
        }));
        styledChunks.push(...styled);
    });

    let previous: ChunkNode |  undefined;
    let current: ChunkNode | undefined;

    styledChunks.sort((first, second) => first.start - second.start)
    .forEach((chunk) => {
        current = {
            prev: previous,
            chunk
        };
        if (!first) {
            first = current;
        }

        if (previous) {
            previous.next = current;
        }

        previous = current;
    });

    current = first;
    while (current) {
        if (current.prev) {
            const previous = current.prev;
            const target = current;
            // If overlap, else do nothing..
            if (target.chunk.start < previous.chunk.end) {
                if (target.chunk.start === previous.chunk.start) {
                    if (target.chunk.end === previous.chunk.end) {
                        // Merge styles
                        // Delete one of them
                        const next = target.next;
                        previous.next = target.next;
                        if (next) {
                            next.prev = previous;
                        }

                        previous.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);
                        current = previous;
                    } else if (target.chunk.end < previous.chunk.end) {
                        // Case 2
                        const start = target.chunk.end;
                        const end =  previous.chunk.end;
                        const style = previous.chunk.style;
                        previous.chunk.end = target.chunk.end;
                        previous.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);

                        target.chunk.start = end;
                        // Delete target
                        const next = target.next;
                        previous.next = target.next;
                        if (next) {
                            next.prev = previous;
                        }

                        insertInOrder(previous, {
                            chunk: {
                                start,
                                end,
                                style
                            }
                        });
                    } else {
                        previous.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);
                        // Generate new chunk
                        // [previous.end, target.end]
                        const next = target.next;
                        previous.next = target.next;
                        if (next) {
                            next.prev = previous;
                        }

                        current = previous;
                        insertInOrder(previous, {
                            chunk: {
                                start: previous.chunk.end,
                                end: target.chunk.end,
                                style: target.chunk.style
                            }
                        });
                    }
                } else {
                    // We can assume that target.chunk.start > previous.chunk.start
                    // because the list is sorted
                    if (target.chunk.end === previous.chunk.end) {
                        // Case 3
                        previous.chunk.end = target.chunk.start;
                        target.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);
                    } else if (target.chunk.end < previous.chunk.end) {
                        // Case 4
                        const end = previous.chunk.end;
                        const style = previous.chunk.style;
                        previous.chunk.end = target.chunk.start;
                        target.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);
                        // Insert new chunk
                        // [target.end , end]
                        insertInOrder(target, {
                            chunk: {
                                start: target.chunk.end,
                                end,
                                style
                            }
                        });
                    } else {
                        const aEnd = previous.chunk.end;
                        const bEnd = target.chunk.end;
                        const style = target.chunk.style;

                        previous.chunk.end = target.chunk.start;
                        target.chunk.end = aEnd;
                        target.chunk.style = mergeStyles(previous.chunk.style, target.chunk.style);
                        // Case 6
                        // Insert new chunk
                        // [aEnd, bEnd]
                        insertInOrder(target, {
                            chunk: {
                                start: aEnd,
                                end: bEnd,
                                style
                            }
                        });
                    }
                }
            }
        }

        current = current.next;
    }

    current = first;
    const conciliateChunks: StyledChunks[] = [];
    while (current) {
        conciliateChunks.push(current.chunk);
        current = current.next;
    }

    return fillInChunks(conciliateChunks, textToHighlight.length);
};
