import {DataType, PlotDataRequest, PlotDataResponse} from "../types/PlotData";
import {useQuery} from "react-query";
import axios from "axios";
import {BaseDocument, DocumentType, ScicartaDocument} from "../types/DocumentData";
import {useStoreDispatch} from "../store/store";
import {addEntitiesAction} from "../store/actions/entities/addEntity";
import {TreeFilter, TreeFilterElement, TreeFilterType} from "../types/TreeFilter";
import {setTreeFilterAction} from "../store/actions/treeFilter/setTreeFilter";
import {documentsAddDocumentAction} from "../store/actions/documents/addDocuments";
import {Entity} from "../types/Entities";
import {abbreviate} from "../utils/Number";

export const getDocumentType = (documentId: string) : DocumentType => {
    if (documentId.startsWith('NCT')) {
        return DocumentType.CLINICAL_TRIAL;
    } else if (documentId.includes('.')) {
        return DocumentType.BIORXIV;
    }

    return DocumentType.PUBMED;
}

const sanitizeAndAbbreviate = (numberLike: string | number | undefined | null): string => {
    if (numberLike === undefined || numberLike === null) {
        return '0';
    }

    if (typeof numberLike === 'string') {
        numberLike = Number.parseInt(numberLike);
    }

    return abbreviate(numberLike);
}

const buildFilterTree = (container: Array<TreeFilterElement>, rawEntities: object, entityMap: Record<string, string>): void => {
    Object.entries(rawEntities).forEach((([name, rawContent]) => {
        const element: TreeFilterElement = {
            type: TreeFilterType.GROUP,
            name,
            content: []
        };

        if (Array.isArray(rawContent)) {
            element.content.push(...(rawContent.map((item): TreeFilterElement => {
                const id = item.conceptId as string;
                return {
                    id,
                    type: TreeFilterType.ITEM,
                    name: entityMap[id],
                    foundCount: item.count,
                    foundAlias: item.foundNames
                };
            })));
        } else {
            buildFilterTree(element.content, rawContent, entityMap);
        }

        container.push(element);
    }));
}

/**
 * This is an implementation of  API-split using the first version of the plot.
 * The actual response of the service includes lots more of information.
 * Includes:
 *  - plot data
 *  - entities
 *  - document
 *
 * This service will extract all that information and store in separate objects for retrieving, this will allow
 * a seamless transition to the new api without affecting the architecture.
 * @param request
 */
export const usePlotData = (request?: PlotDataRequest) => {
    const dispatch = useStoreDispatch();

    const { isLoading, isRefetching, error, data, refetch } = useQuery({
        queryKey: [ 'plot-data', request?.query ? {
            query: request.query,
            filter_entities: request.filters.entities,
            filter_keywords: request.filters.keywords,
            sources: [...request.sources].join(', ')
        } : undefined ],
        queryFn: async (): Promise<PlotDataResponse> => {
            if (!request?.query) {
                throw new Error('Invalid path. This is likely a bug');
            }

            const queryParams: Array<string> = [
                `search_term=${request.query}`,
                `sources=${[...request.sources].join(',')}`
            ];

            if (request.filters.keywords) {
                queryParams.push(`optional_keywords=${request.filters.keywords.join(',')}`);
            }

            if (request.filters.entities) {
                queryParams.push(`entities=${request.filters.entities?.join(',')}`);
            }

            const response = await axios.get(
                `api/plot?&${queryParams.join('&')}`
            );

            // extract entities
            let entities: Array<Entity> = [];
            if (response.data?.entities?.canonicalNames) {
                const entityMap = (response.data?.entities?.canonicalNames as Record<string, string>);
                entities = Object.entries(entityMap).map(([id, displayName]) => ({
                    id,
                    displayName
                }));
                dispatch(addEntitiesAction(entities));
            }

            // extract entity navigation
            if (response.data?.entities?.entities && response.data?.entities?.canonicalNames) {
                const entityTreeList =  response.data?.entities?.entities;
                const entityMap = response.data?.entities?.canonicalNames;
                const navigation: TreeFilter = {
                    content: []
                };
                buildFilterTree(navigation.content, entityTreeList, entityMap)
                dispatch(setTreeFilterAction(navigation));
            }

            // extract documents
            const documents: Record<string, ScicartaDocument> = {};

            const plotData = {
                title: response.data.layout.title,
                x_axis: {
                    title: '',
                    range: response.data.layout.xaxis.range
                },
                y_axis: {
                    title: response.data.layout.yaxis.title,
                    range: response.data.layout.yaxis.range,
                    ticks: response.data.layout.yaxis.tickvals.map((v: string, i: number) => ({
                        name: response.data.layout.yaxis.ticktext[i],
                        value: v
                    }))
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data: response.data.data.map((plotData: any) => {

                    const element = entities.find(value =>  value.id === plotData.name);
                    const name = `${element?.displayName ?? 'Most recent papers'} (${sanitizeAndAbbreviate(plotData.x.length)})`;
                    const nameForLegends = `${element?.displayName ?? 'Most recent papers'}`;

                    return {
                        id: plotData.name,
                        name,
                        filter_type: 'keywords', // This value can be obtained checking if the name is part of the entities list
                        points: plotData.x.map((x: unknown, i: number) => {
                            const documentId: string = plotData.customdata[i].pmid;

                            const type = getDocumentType(documentId);

                            const displayName = nameForLegends; // The name will change if we load entities

                            // Extract legend
                            let legend = '';
                            switch (type) {
                                case DocumentType.PUBMED:
                                    legend = `${displayName} | ${sanitizeAndAbbreviate(plotData.citation_count[i])} citations paper`;
                                    break;
                                case DocumentType.BIORXIV:
                                    legend = `${displayName} | Downloads ${sanitizeAndAbbreviate(plotData.citation_count[i])}`;
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    legend = `${displayName} | Phase ${plotData.customdata[i].phase} trial`
                                    break;
                            }

                            // Extract shape
                            let shape: string | undefined = undefined;
                            switch (type) {
                                case DocumentType.PUBMED:
                                    break;
                                case DocumentType.BIORXIV:
                                    shape = 'square';
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    shape = 'diamond';
                                    break;
                            }

                            let defaultHighlightProperty: string | undefined = undefined;
                            switch (type) {
                                case DocumentType.PUBMED:
                                    defaultHighlightProperty = 'abstract';
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    defaultHighlightProperty = 'brief_summary';
                                    break;
                            }

                            // Build document
                            const documentBase: Omit<BaseDocument, 'type'> = {
                                id: documentId,
                                highlighting: {
                                }
                            };

                            if (defaultHighlightProperty) {
                                documentBase.highlighting[defaultHighlightProperty] = {
                                    entities: plotData.customdata[i].entities
                                }
                            }

                            switch (type) {
                                case DocumentType.PUBMED:
                                    documents[documentId] = {
                                        ...documentBase,
                                        type,
                                        title: plotData.customdata[i].title,
                                        abstract: plotData.customdata[i].abstract,
                                        brief_summary: plotData.customdata[i].brief_summary,
                                        journal: plotData.customdata[i].journal,
                                        publication_type: plotData.customdata[i].publication_type,
                                        doi_href: plotData.customdata[i].doi,
                                        citations: plotData.customdata[i].citation ?? 0,
                                        affiliation: plotData.customdata[i].affilation?.split(',') ?? [],
                                        authors: plotData.customdata[i].author?.split(',') ?? [],
                                        companies: plotData.customdata[i].company?.split(',') ?? [],
                                        substances: plotData.customdata[i].substance?.split(',') ?? [],
                                        mesh: plotData.customdata[i].mesh?.split(',') ?? [],
                                        keywords: plotData.customdata[i].keywords?.split(',') ?? []
                                    };
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    documents[documentId] = {
                                        ...documentBase,
                                        type,
                                        phase: plotData.customdata[i].phase,
                                        status: plotData.customdata[i].status,
                                        title: plotData.customdata[i].title,
                                        brief_summary: plotData.customdata[i].brief_summary,
                                        description: plotData.customdata[i].detailed_description,
                                        sponsor: plotData.customdata[i].author?.split(',') ?? [],
                                        mesh: plotData.customdata[i].mesh?.split(',') ?? []
                                    };
                                    break;
                            }

                            // Build bubble data point
                            return {
                                x,
                                y: plotData.y[i],
                                legend,
                                title: plotData.customdata[i].title,
                                document_id: documentId,
                                type: DataType.BUBBLE,
                                size: plotData.marker.size[i],
                                shape,
                                content: plotData.marker.shape[i]
                            }
                        })
                    }
                })
            };

            dispatch(documentsAddDocumentAction(Object.values(documents)));
            return plotData;
        },
        enabled: !!request?.query,
        retry: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    return {
        isLoading: isLoading || isRefetching,
        error,
        data,
        refetch
    };
}
