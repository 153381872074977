/**
 * Interfaces for the request and response of document data.
 * Once we have the plot with the contents of what documents we need to load, we can begin loading them on batches
 * as we see fit. We could even try to prioritize loading the "important" ones if that makes sense.
 * The loading will happen async and in batches.
 *
 * A local cache can be used to prevent loading documents that were available in the previous requests.
 */
export interface DocumentDataRequest {
    documents: Array<string>;
}

export interface DocumentDataResponse {
    documents: Array<Document>;
}

interface HighlightingUnit {
    start: number;
    end: number;
}

interface HighlightingSection {
    entities?: {
        [entityId: string]: Array<HighlightingUnit>
    }
}

export interface BaseDocument {
    id: string;
    type: DocumentType;
    highlighting: {
        [property: string]: HighlightingSection
    };
}

export enum DocumentType {
    LOADING = 'loading',
    PUBMED = 'pubmed',
    CLINICAL_TRIAL = 'clinical_trial',
    BIORXIV = 'biorxiv',
    CONFERENCES = 'conferences'
}

export type ScicartaDocument = DocumentLoading | DocumentPubmed | DocumentClinicalTrial;

export interface DocumentLoading extends BaseDocument {
    type: DocumentType.LOADING;
    title: string;
}

export interface DocumentPubmed extends BaseDocument {
    type: DocumentType.PUBMED;
    title: string;
    abstract: string;
    brief_summary: string;
    journal: string;
    publication_type: string;
    doi_href: string;

    citations: number;

    affiliation: Array<string>;
    authors: Array<string>;
    companies: Array<string>;
    substances: Array<string>;
    mesh: Array<string>;
    keywords: Array<string>;
}

export interface DocumentClinicalTrial extends BaseDocument {
    type: DocumentType.CLINICAL_TRIAL;

    phase: string;
    status: string;

    title: string;
    brief_summary: string;
    description: string;
    sponsor: Array<string>;
    mesh: Array<string>;
}
