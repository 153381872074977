/*
Utility functions to share the query param.

We are going to use a new query param format.

We won't preserve the order unless it's super important, in said case we will use an additional param to provide the
order.
 */

import {AppQuery, FilterElement, FilterElementType} from "../types/AppQuery";
import {URLBuilder} from '@letscooee/url-builder';

// Used for the query.
const PARAM_QUERY = 'q';
// Used as an array of the filter elements of type string.
const PARAM_FILTER_STRING = 'fs';
// Used as an array of the filter elements of type entity.
const PARAM_FILTER_ENTITY = 'fe';
// Used as an array of sources.
const PARAM_SOURCES = 's';

export const buildShareAppQueryUrl = (query: AppQuery, baseUrl: string): string => {
    const builder = new URLBuilder(baseUrl);
    builder.appendQueryParam(PARAM_QUERY, query.query);
    query.filters.forEach(f => {
        const param = f.type === FilterElementType.STRING ? PARAM_FILTER_STRING : PARAM_FILTER_ENTITY;
        const value = f.type === FilterElementType.STRING ? f.displayName : f.id;
        builder.appendQueryParam(param, value);
    });

    query.sources.forEach(source => {
        builder.appendQueryParam(PARAM_SOURCES, source);
    });

    return builder.toString();
}

export const loadAppQueryFromUrl = (url: string): AppQuery => {
    const params = new URL(url).searchParams;
    const query = params.get(PARAM_QUERY) ?? '';
    const filterString: Array<FilterElement> = params.getAll(PARAM_FILTER_STRING).map(p => ({
        id: p.toLowerCase(),
        type: FilterElementType.STRING,
        displayName: p,
    }));
    const filterEntities: Array<FilterElement> = params.getAll(PARAM_FILTER_ENTITY).map(p => ({
        id: p,
        type: FilterElementType.ENTITY,
        isLoading: true,
        displayName: p
    }));

    const sources = params.getAll(PARAM_SOURCES);

    return {
        query,
        filters: filterString.concat(filterEntities),
        sources
    };
}
