import * as React from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify'
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import {useEffect, useRef} from "react";
import {AddEventData, RemoveEventData} from "@yaireo/tagify";
import {StoredFilterElement} from "../../types/AppQuery";

interface SourceSelectorProps {
    selected: ReadonlyArray<string>;
    available: ReadonlyArray<string>;
    select: (source: string) => void;
    unselect: (source: string) => void;
}

const useSelfUpdateRef = <T,>(initialValue: T, updateTo: T) => {
    const ref = useRef<T>(initialValue);
    useEffect(() => {
        ref.current = updateTo;
    }, [updateTo]);

    return ref;
}

const emptyFunction = () => '';

type TagifyFilterElement = StoredFilterElement & {
    value: string;
    __isValid: boolean;
}

export const SourceSelector: React.FunctionComponent<SourceSelectorProps> = (props) => {
    // Todo: Take the state/store call out of this component

    const tagifyRef = useRef();
    const selectedRef = useSelfUpdateRef<ReadonlyArray<string>>([], props.selected);
    const selectRef = useSelfUpdateRef(emptyFunction, props.select);
    const unselectRef = useSelfUpdateRef(emptyFunction, props.unselect);

    return (
        <Tags
            tagifyRef={tagifyRef}
            settings={ {
                userInput:false,
                dropdown : {
                    whitelist: props.available,
                    classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
                    enabled: 0,             // <- show suggestions on focus
                    closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
                },
                hooks: {
                    beforeRemoveTag: () => {
                        if (selectedRef.current.length > 1) {
                            return Promise.resolve();
                        }

                        return Promise.reject();
                    }
                },
                callbacks: {
                    add: (e: CustomEvent<AddEventData<TagifyFilterElement>>) => {
                        if (e.detail.data?.value) {
                            selectRef.current(e.detail.data.value);
                        }
                    },
                    remove: (e: CustomEvent<RemoveEventData<TagifyFilterElement>>) => {
                        if (e.detail.data?.value) {
                            unselectRef.current(e.detail.data.value);
                        }
                    }
                }
            } }
            value={ props.selected}
            autoFocus={true}
            whitelist={props.available}
            showFilteredDropdown= "a"
            loading={false}
        />
    );
};
