import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {ModalDialogTitle} from "./ModalDialogTitle";
import {Box, Link} from "@mui/material";

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));


interface ContactUsProps {
    isOpen : boolean;
    handleClose: () => void;
}

export const ContactUs: React.FunctionComponent<ContactUsProps> = (props) => {

    return (
        <div>
            <ModalDialog
                keepMounted={true}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <ModalDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Contact Us
                </ModalDialogTitle>
                <DialogContent dividers>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >
                        <Typography gutterBottom>
                            Contact us at  <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link>
                        </Typography>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </div>
    );
}
