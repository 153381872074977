import * as React from 'react';
import { AppBar, Typography, Toolbar, Button, Box } from "@mui/material";
import { ProfileDropdown} from "./ProfileDropdown";
import {Link, useLocation} from "react-router-dom";

export const Header: React.FunctionComponent = () => {
    const location = useLocation();
   // const { path } = useRouteMatch();

    return (
        <AppBar
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
            <Toolbar variant="dense">
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: 'flex',
                        fontFamily: 'monospace',
                        fontWeight: 500,
                        letterSpacing: '.01rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Scicarta
                </Typography>
                <Box sx={{ flexGrow: 1, display: 'flex'}}>
                    <Typography sx={{ my: 2, color: 'white', display: 'block' }}>
                        skim science quickly
                    </Typography>
                </Box>
                <Button
                    component={Link}
                    color="inherit"
                    state={{ background: location }}
                    to={'/quickstart'}
                >
                    Quickstart
                </Button>
                <Button
                    component={Link}
                    color="inherit"
                    to="help"
                    >
                        Help
                </Button>
                <ProfileDropdown/>
            </Toolbar>
        </AppBar>
    );
};
