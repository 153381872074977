import * as React from 'react';
import {Button, IconButton, InputAdornment, Stack} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import Tags from '@yaireo/tagify/dist/react.tagify'
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import {style} from 'typestyle';
import {ColorPalette} from '../../utils/Color';
import {useSelector} from "react-redux";
import {getFilters} from "../../store/selectors";
import {useStoreDispatch} from "../../store/store";
import {addFilterAction} from "../../store/actions/addFilter";
import {FilterElementType, StoredFilterElement} from "../../types/AppQuery";
import {removeFilterAction} from "../../store/actions/removeFilter";
import Tagify, {AddEventData, RemoveEventData} from "@yaireo/tagify";
import {clearFiltersAction} from "../../store/actions/clearFilters";
import {Top} from "react-spaces";
import {useMeasure} from "react-use";
import {LoadingButton} from "@mui/lab";

const tagsInputGroupClassName = style({
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '4px',
    paddingRight: 12,
    $nest: {
        '&:hover': {
            border: '1px solid rgba(0,0,0,0.87)',
        },
        '& .tags-input': {
            flexGrow: 1,
            borderRadius: '4px',
        },
        '& .tagify': {
            border: 0
        }
    }
});

const id = 'show-query';

type TagifyFilterElement = StoredFilterElement & {
    value: string;
    isLoading: boolean;
    __isValid: boolean;
}

const template = function(this: Tagify, value: TagifyFilterElement): string {
    return `<tag 
                title='${ value.displayName }' 
                contenteditable='false'
                spellcheck="false"
                class='tagify__tag ${value.isLoading ? 'tag-loading' : ''}'
                style="${value.color !== undefined ? '--tag-bg:' + ColorPalette.getColor(value.color).pastel.toString() + '; --tag-bg-loading:' + ColorPalette.getColor(value.color).accent.toRawString() + ';' : '' }" 
                ${this.getAttributes(value)}
            >
                <x title='remove tag' class='tagify__tag__removeBtn'></x>
                <div style="border-radius: ${value.type === FilterElementType.ENTITY ? '4px' : '16px'};">
                    <span class="tagify__tag-text">${ value.isLoading ? '' : value.displayName }</span>
                </div>
            </tag>`;
};

interface FilterProps {
    openShareQuery: () => void;
    onSearch: () => void;
    isSearching: boolean;
}

export const Filter: React.FunctionComponent<FilterProps> = ({openShareQuery, onSearch, isSearching}) => {
    // Todo: Take the state/store call out of this component
    const filters = useSelector(getFilters);
    const dispatch = useStoreDispatch();

    const [ref, { height }] = useMeasure<HTMLDivElement>();

    const values = React.useMemo(() =>
            JSON.stringify(
                Object.values(filters)
                .map(k => ({
                    value: k.displayName,
                    ...k
                })))
        , [ filters ]
    );

    const handleClearQuery = () => {
        dispatch(clearFiltersAction());
    };

    const graphq = {
        background: "white",
    };

    return (
        <Top size={height+10} style={graphq} order={2}>
        <Stack  direction="row" spacing={1} flex={ 1 }  alignItems="center"  ref={ref}>
            <Stack className={ tagsInputGroupClassName } direction="row" spacing={ 0 } flexGrow={ 1 } alignItems="center">
                <Tags
                    id={ id }
                    name={ id }
                    aria-describedby={ id }
                    value={ values }
                    settings={ {
                        editTags: false,
                        templates: {
                            tag: template
                        },
                        callbacks: {
                            add: (e: CustomEvent<AddEventData<TagifyFilterElement>>) => {
                                const data = e.detail?.data;
                                if (data && !data.id) {
                                    dispatch(addFilterAction({
                                        id: data.value.toLowerCase(),
                                        type: FilterElementType.STRING,
                                        displayName: data.value
                                    }));
                                }
                            },
                            remove: (e: CustomEvent<RemoveEventData<TagifyFilterElement>>) => {
                                if (e.detail?.data?.id) {
                                    dispatch(removeFilterAction(e.detail.data.id));
                                }
                            }
                        }
                    } }
                />
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear query"
                        onClick={handleClearQuery}
                        edge="end"
                    >
                        <Cancel/>
                    </IconButton>
                </InputAdornment>
            </Stack>
            <Button variant="outlined" onClick={openShareQuery} startIcon={<ShareIcon />} sx={{ width: 120, minWidth:120}}>Share</Button>
            <LoadingButton variant="contained" loading={isSearching} onClick={onSearch} startIcon={<SearchIcon />} sx={{ width: 120, minWidth:120}}>Analyze</LoadingButton>
        </Stack>
        </Top>
    );
};
