import {StoreActionReducer} from "../../reducer";
import {StoreActionType, StoreState} from "../../store";

export interface StoreActionDocumentsClearSelected {
    type: StoreActionType.DOCUMENTS_CLEAR_SELECTED;
}

export const documentsClearSelectedAction = (): StoreActionDocumentsClearSelected => ({
    type: StoreActionType.DOCUMENTS_CLEAR_SELECTED
});

export const documentsClearSelectedReducer: StoreActionReducer<StoreActionType.DOCUMENTS_CLEAR_SELECTED> = (state, _action): StoreState | void  => {
    state.documentsStore.selectedDocuments = {};
}
