import * as React from 'react';
import { Typography} from '@mui/material';
import {useHighlighting} from "../highlighter/HighLightText";
import {StyledChunks, StyledWords} from "../highlighter/HighLightReconciliation";

interface PaperViewerParagraphProps {
    title: string;
    content: string;
    isInline?: boolean;
    styledChunks?: ReadonlyArray<StyledChunks>;
    styledWords?: ReadonlyArray<StyledWords>;
}

export const PaperViewerParagraph: React.FunctionComponent<PaperViewerParagraphProps> = (props) => {
    const component = props.isInline ? 'span' : 'p';
    const content = useHighlighting(props.content, {
        chunks: props.styledChunks,
        words: props.styledWords
    });

    return (
        <Typography component="div" align={"justify"} sx={{ fontSize:12}}>
            <Typography component="strong" sx={{ color:"#555",fontWeight: 600,fontSize:12}}>
                {props.title}
            </Typography>
            <Typography component={component} sx={{ fontSize:12}} > <span dangerouslySetInnerHTML={{__html: content}} /> </Typography>
        </Typography>
    );
};
