import {buildShareAppQueryUrl, loadAppQueryFromUrl} from "../../utils/ShareQuery";
import Config from "../../Config";
import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getFilters, getQuery, getSelectedSources} from "../../store/selectors";
import {setAppQueryAction} from "../../store/actions/setAppQuery";
import {useStoreDispatch} from "../../store/store";
import {useLocation, useNavigate} from "react-router-dom";

export const useSyncUrl = () => {

    const query = useSelector(getQuery);
    const filters = useSelector(getFilters);
    const sources = useSelector(getSelectedSources);
    const dispatch = useStoreDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const firstLoad = useRef(true);

    // On load, set the state from the url (if any!)
    useEffect(() => {
        const appQuery = loadAppQueryFromUrl(window.document.documentURI);
        if (appQuery.query || appQuery.sources.length > 0 || appQuery.filters.length > 0) {
            dispatch(setAppQueryAction(appQuery));
        }
    }, []);


    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
        const url = buildShareAppQueryUrl({
            query,
            filters: Object.values(filters),
            sources: [...sources]
        }, Config.baseUrl);

        const targetSearch = '?' + new URL(url).searchParams.toString();

        if (location.search !== targetSearch) {
            navigate({
                search: targetSearch
            });
        }
    }, [query, filters, sources, navigate, location]);

    return firstLoad.current;
};
