import {StoreActionType} from "../../store";
import {Entity} from "../../../types/Entities";
import {StoreActionReducer} from "../../reducer";
import {FilterElementType} from "../../../types/AppQuery";

export interface StoreActionAddEntities {
    type: StoreActionType.ADD_ENTITIES;
    entities: ReadonlyArray<Entity>;
}

export const addEntitiesAction = (entities: ReadonlyArray<Entity>): StoreActionAddEntities => ({
    type: StoreActionType.ADD_ENTITIES,
    entities
});

export const addEntitiesReducer: StoreActionReducer<StoreActionType.ADD_ENTITIES> = (state, action) => {
    const loadingFilters = Object.fromEntries(Object.entries(state.filters)
        .filter(([_key, f]) => f.type === FilterElementType.ENTITY && f.isLoading)
    );

    action.entities.forEach(entity => {
        state.entities[entity.id] = entity;
        if (entity.id in loadingFilters) {
            const loadingFilter = loadingFilters[entity.id];
            if (loadingFilter.type === FilterElementType.ENTITY) {
                loadingFilter.displayName = entity.displayName;
                loadingFilter.isLoading = false;
            }

            if (state.filters[entity.id]) {
                state.filters[entity.id].displayName = entity.displayName;
            }
        }
    });
};
