import * as React from 'react';
import {PaperViewerWrapper} from "../PaperViewerWrapper";
import {PaperViewerParagraph} from "../PaperViewerParagraph";
import {Box} from '@mui/material';
import {DocumentClinicalTrial} from "../../../types/DocumentData";
import {PaperViewerDocumentProps} from "./PaperViewerDocumentProps";
import {useStyledWords} from "./useStyledWords";
import {useStyledChunks} from "./useStyledChunks";
import {joinOrUndefined} from "../../../utils/Array";

export const PaperViewerDocumentClinicalTrial: React.FunctionComponent<PaperViewerDocumentProps<DocumentClinicalTrial>> = (props) => {

    const searchStyles = useStyledWords(props.appQuery);
    const chunks = useStyledChunks(props.document.highlighting, props.appQuery.filters);

    const sponsor = joinOrUndefined(props.document.sponsor, ', ');
    const mesh = joinOrUndefined(props.document.mesh, ', ');

    return (
        <Box mr={2}>
            <PaperViewerWrapper title={props.document.title} link={"https://clinicaltrials.gov/ct2/show/" + props.document.id}>
                {sponsor && <PaperViewerParagraph title={"Sponsor:"}
                                      content={sponsor}
                                      styledWords={searchStyles}
                                      isInline
                />}
                <PaperViewerParagraph title={"Paper id:"}
                                      content={props.document.id}
                                      isInline
                />
                <PaperViewerParagraph title={"Status:"}
                                      content={props.document.status}
                                      styledWords={searchStyles}
                                      isInline
                />
                <PaperViewerParagraph title={"Brief Summary"}
                                      content={props.document.brief_summary}
                                      styledWords={searchStyles}
                                      styledChunks={chunks.brief_summary}
                />
                {props.document.description && <PaperViewerParagraph title={"Detail Description"}
                                      styledWords={searchStyles}
                                      content={props.document.description}
                                      styledChunks={chunks.description}
                />}
                {mesh && <PaperViewerParagraph title={"MeSH"}
                                      content={mesh}
                                      styledWords={searchStyles}
                />}
            </PaperViewerWrapper>
        </Box>
    );
};
