import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {Chart} from 'highcharts';
import highChartsMoreFactory from 'highcharts/highcharts-more';
import highchartsCustomEventsFactory from 'highcharts-custom-events';
import { style } from 'typestyle';
import {PlotDataResponse} from "../../types/PlotData";
import {useSelector} from "react-redux";
import {getFilters, getSelectedDocuments} from "../../store/selectors";
import {useLayoutEffect, useMemo, useRef} from "react";
import {useCurrentSpace} from "react-spaces";
import {buildOptions, HighChartsOptions} from "./Utils";
import {usePreviousDistinct} from "react-use";
import {useStoreDispatch} from "../../store/store";
import {setLastHoveredAction} from "../../store/actions/graph/setLastHovered";
import {setLastClickedAction} from "../../store/actions/graph/setLastClicked";

highChartsMoreFactory(Highcharts);
// This library is using an old type that is no longer compatible because of a missing variable in a SVG type
// It's probably not a big deal.
highchartsCustomEventsFactory(Highcharts as unknown as Parameters<typeof highchartsCustomEventsFactory>[0]);

const plotClassNameBase = style({
    width: '100%',
    borderTop: 'none',
    overflow: 'hidden'
});

interface GraphProps {
    plotData?: PlotDataResponse;
}

export const Graph: React.FunctionComponent<GraphProps> = (props) => {
    const graphRef = useRef<Chart>();
    const { size } = useCurrentSpace();

    const filters = useSelector(getFilters);
    const dispatch = useStoreDispatch();
    const selectedDocuments = useSelector(getSelectedDocuments);

    const selectedDocumentsValues = useMemo(() => Object.values(selectedDocuments), [selectedDocuments]);

    const options = React.useMemo((): HighChartsOptions | undefined => {
        const plotData = props.plotData;
        const filtersAsArray = Object.values(filters);
        return buildOptions(plotData, filtersAsArray, {
            onPointHover: point => dispatch(setLastHoveredAction(point)),
            onPointClick: point => dispatch(setLastClickedAction(point))
        }, selectedDocumentsValues);
    }, [ props.plotData, filters, dispatch, selectedDocumentsValues ]);

    // Keeps track of the previous distinct options - useful to preserve the current graph while a new one is loading.
    const prevOptions = usePreviousDistinct(options);

    const graphCreated = React.useCallback((graph: Chart) => {
        graphRef.current = graph;
    }, []);

    useLayoutEffect(() => {
        if (graphRef.current) {
            graphRef.current?.setSize(size.width, size.height);
        }
    });

    if (!options && !prevOptions) {
        return null;
    }

    return <HighchartsReact
        className={ plotClassNameBase }
        highcharts={ Highcharts }
        callback={ graphCreated }
        options={ options || prevOptions }
    />;
};
