import {StoreActionType} from "../store";
import {StoreActionReducer} from "../reducer";
import {AppQuery, FilterElementType, StoredFilterElement} from "../../types/AppQuery";
import {castDraft} from 'immer';

export interface StoreActionSetAppQuery {
    type: StoreActionType.SET_APP_QUERY;
    appQuery: AppQuery;
}

export const setAppQueryAction = (appQuery: AppQuery): StoreActionSetAppQuery => ({
    type: StoreActionType.SET_APP_QUERY,
    appQuery
});

export const setAppQueryReducer: StoreActionReducer<StoreActionType.SET_APP_QUERY> = (state, action) => {
    state.query = action.appQuery.query;
    state.filters = Object.fromEntries(
        Array.from(action.appQuery.filters.values()).map((value, index) => {
            if (value.type === FilterElementType.ENTITY && !state.entities[value.id]) {
                state.entities[value.id] = castDraft({
                    ...value
                });
            }

            const filter: StoredFilterElement = {
                ...value,
                color: index
            };

            return [value.id, filter];
        })
    );
    state.selectedSources = new Set(action.appQuery.sources.filter(s => state.availableSources.has(s)));
};
/*
const syncSet = <T>(toUpdate: Set<T>, immutableSet: ReadonlySet<T>) => {
    toUpdate.forEach(e => {
        if (!immutableSet.has(e)) {
            toUpdate.delete(e);
        }
    })
    immutableSet.forEach(e => toUpdate.add(e));
}
*/
