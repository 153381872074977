import * as React from 'react';
import {Skeleton, Stack, Typography} from '@mui/material';
interface PaperViewerLoadingParagraphProps {
    isInline?: boolean;
}

const height = '25px';

export const PaperViewerLoadingParagraph: React.FunctionComponent<PaperViewerLoadingParagraphProps> = (props) => {
    const contentComponent = props.isInline ? 'span' : 'p';

    return (
        <Typography align={"justify"} sx={{ fontSize:12}}>
            <Stack direction={props.isInline ? 'row' : 'column'} spacing={props.isInline ? 2 : 0}>
                <Typography component="strong" sx={{ color:"#555",fontWeight: 600,fontSize:12}}>
                    <Skeleton variant="text" width="90px" height={height} />
                </Typography>
                <Typography
                    component={contentComponent}
                    sx={{fontSize:12, flexGrow: 1}}
                >
                    <Skeleton variant="text" height={height} />
                    { !props.isInline && <>
                        <Skeleton variant="text" height={height} />
                        <Skeleton variant="text" height={height} />
                        <Skeleton variant="text" height={height} />
                    </> }
                </Typography>
            </Stack>
        </Typography>
    );
};
