import * as React from 'react';
import {PaperViewerWrapper} from "../PaperViewerWrapper";
import {Box} from '@mui/material';
import {DocumentLoading} from "../../../types/DocumentData";
import {PaperViewerLoadingParagraph} from "../PaperViewerLoadingParagraph";
import {PaperViewerDocumentProps} from "./PaperViewerDocumentProps";

export const PaperViewerDocumentLoading: React.FunctionComponent<PaperViewerDocumentProps<DocumentLoading>> = (props) => {

    return (
        <Box mr={2}>
                <PaperViewerWrapper title={props.document.title} link="#">
                    <PaperViewerLoadingParagraph isInline />
                    <PaperViewerLoadingParagraph isInline />
                    <PaperViewerLoadingParagraph isInline />
                    <PaperViewerLoadingParagraph />
                </PaperViewerWrapper>
        </Box>
    );
};
