import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionRemoveFilter {
    type: StoreActionType.REMOVE_FILTER;
    filterId: string;
}

export const removeFilterAction = (filterId: string): StoreActionRemoveFilter => ({
    type: StoreActionType.REMOVE_FILTER,
    filterId
});

export const removeFilterReducer: StoreActionReducer<StoreActionType.REMOVE_FILTER> = (state, action): StoreState | void => {
    delete state.filters[action.filterId];
};
