// from https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export const abbreviate = (num: number): string => {
    if (num === null) {
        return '';
    }

    if (num === 0) {
        return '0';
    }

    const b = (num).toPrecision(2).split('e'); // get power
    const k = b.length === 1 ? 0 : Math.floor(Math.min(+(b[1].slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
    const c = k < 1 ? num.toFixed(0) : (num / Math.pow(10, k * 3)).toFixed(1); // divide by power
    const d = +c < 0 ? c : Math.abs(+c); // enforce -0 is 0
    const e = d + [ '', 'K', 'M', 'B', 'T' ][k]; // append power
    return e;
};
