import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";
import {produce} from "immer";

export interface StoreActionRemoveSource {
    type: StoreActionType.REMOVE_SOURCE;
    source: string;
}

export const removeSourceAction = (source: string): StoreActionRemoveSource => ({
    type: StoreActionType.REMOVE_SOURCE,
    source
});

export const removeSourceReducer: StoreActionReducer<StoreActionType.REMOVE_SOURCE> = (state, action): StoreState | void => {
    state.selectedSources = produce(state.selectedSources, (draft => {
        draft.delete(action.source);
    }));
};
