import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { enableMapSet } from 'immer';
import keycloak from "./App/Keycloak";
import {ReactKeycloakProvider} from "@react-keycloak/web";

// Setup immer
enableMapSet();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Due to a bug in @react-keycloak/web, the component does not work well in strict mode - temporally taking this out
// see https://github.com/react-keycloak/react-keycloak/issues/182
root.render(
    <ReactKeycloakProvider authClient={ keycloak } initOptions={{ onLoad: 'login-required', checkLoginIframe: false}}>
        <React.StrictMode>
                <App/>
    </React.StrictMode>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
