import {StoreActionReducer} from "../../reducer";
import {StoreActionType, StoreState} from "../../store";

export interface StoreActionRemoveSelectedDocument {
    type: StoreActionType.REMOVE_SELECTED_DOCUMENT;
    document_id: string;
}

export const documentsRemoveSelectedDocumentAction = (document_id: string): StoreActionRemoveSelectedDocument => ({
    type: StoreActionType.REMOVE_SELECTED_DOCUMENT,
    document_id
});

export const documentRemoveSelectedDocumentsReducer: StoreActionReducer<StoreActionType.REMOVE_SELECTED_DOCUMENT> = (state, action): StoreState | void => {
    if (action.document_id in state.documentsStore.selectedDocuments) {
        delete state.documentsStore.selectedDocuments[action.document_id];
    }
}
