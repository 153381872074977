import {AppQuery, FilterElementType} from "./types/AppQuery";

const apiBaseUrl = '/api';

const withBaseUrl = (path: string) => `${apiBaseUrl}/${path}`;

const getBaseUrl = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}`;
};

const Config = {
    appId: 'pubskape',
    baseUrl: getBaseUrl(),
    apis: {
        urls: {
            base: apiBaseUrl,
            plot: withBaseUrl('plot'),
            enrichments: withBaseUrl('enrichments'),
            papersCount: withBaseUrl('get_papers_count')
        }
    },
    appQueries: [
        {
            query: 'hif2alpha',
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'wg kaelin',
                    displayName: 'WG Kaelin'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'peloton therapeutics',
                    displayName: 'Peloton Therapeutics'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'merck',
                    displayName: 'Merck'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0694897',
                    displayName: 'VHL gene',
                    isLoading: false
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0007134',
                    displayName: 'Renal Cell Carcinoma',
                    isLoading: false
                },
            ],
            sources: ['pubmed']
        },
        {
            query: 'pseudouridine mRNA',
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'd weissman',
                    displayName: 'D Weissman'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'k karikó',
                    displayName: 'K Karikó'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'biontech',
                    displayName: 'BioNTech'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'moderna',
                    displayName: 'Moderna'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'vaccine',
                    displayName: 'vaccine'
                },
            ],
            sources: ['pubmed']
        },
        {
            query: 'undruggable',
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'cm crews',
                    displayName: 'CM Crews'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'protac',
                    displayName: 'PROTAC'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0034678',
                    displayName: 'ras Oncogene',
                    isLoading: false
                }
            ],
            sources: ['pubmed']
        },

        {
            query: 'hurler syndrome "gene therapy"',
            filters: [],
            sources: ['pubmed']
        }
    ] as ReadonlyArray<AppQuery>
};

const ReadOnlyConfig: Readonly<typeof Config> = Config;
export default ReadOnlyConfig;
