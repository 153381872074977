import * as React from 'react';
import {PaperListInformation} from "../PaperListInformation";
import {PaperListInformationDetail} from "../PaperListInformationDetail";
import {DocumentClinicalTrial, ScicartaDocument} from "../../../types/DocumentData";

interface PaperSelectedInformationClinicalTrialProps {
    document : DocumentClinicalTrial;
    getTextByItem: (item: ScicartaDocument)=>string;
    onDeleteDocument: (documentId: string) => void;
}


export const PaperSelectedInformationClinicalTrial: React.FunctionComponent<PaperSelectedInformationClinicalTrialProps> = (props) => {

    return (
        <>
            <PaperListInformation
                title={props.document.brief_summary}
                link={"https://clinicaltrials.gov/ct2/show/" + props.document.id}
                textToCopy={props.getTextByItem(props.document)}
                documentId={props.document.id}
                onDeleteDocument={props.onDeleteDocument}
            >
                <PaperListInformationDetail
                    title={props.document.sponsor.map((text) => text ).join(', ')}
                    content={""}/>
            </PaperListInformation>
        </>
    );
};
