import {StoreActionType, StoreState} from "../../store";
import {Entity} from "../../../types/Entities";
import {StoreActionReducer} from "../../reducer";

export interface StoreActionRemoveEntities {
    type: StoreActionType.REMOVE_ENTITIES;
    entities: ReadonlyArray<Pick<Entity, 'id'> | string>;
}

export const removeEntitiesAction = (entities: ReadonlyArray<Entity>): StoreActionRemoveEntities => ({
    type: StoreActionType.REMOVE_ENTITIES,
    entities
});

export const removeEntitiesReducer: StoreActionReducer<StoreActionType.REMOVE_ENTITIES> = (state, action: StoreActionRemoveEntities): StoreState | void => {
    action.entities.forEach(entity => {
        if (typeof entity === 'string') {
            delete state.entities[entity];
        } else {
            delete state.entities[entity.id];
        }
    });
};
