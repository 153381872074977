import * as React from 'react';
import {useNavigate,useParams} from "react-router-dom";
import {QuickStart} from "./QuickStart";
import {Help} from "./Help";
import {ContactUs} from "./ContactUs";
import {PrivacyPolice} from "./PrivacyPolice";
import {TermsOfUse} from "./TermsOfUse";

export const ModalCustom: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { modal }  = useParams();


    const onDismiss = () => {
        navigate('/');
    }

    if (modal === 'quickstart') {
        return (<QuickStart isOpen={true} handleClose={onDismiss}/>);
    } else if (modal === 'help') {
        return (<Help isOpen={true} handleClose={onDismiss}/>);
    }else if (modal === 'contact-us') {
        return (<ContactUs isOpen={true} handleClose={onDismiss}/>);
    }else if (modal === 'privacy-police') {
        return (<PrivacyPolice isOpen={true} handleClose={onDismiss}/>);
    } else if (modal === 'terms-of-use') {
        return (<TermsOfUse isOpen={true} handleClose={onDismiss}/>);
    } else {
        return null;
    }
};

