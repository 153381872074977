import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontSize: 12,
    },
    components: {
        MuiFormControl: {
            styleOverrides:{
                root:({ownerState}) => ({
                    ...(ownerState.size === 'small' && {
                        height:'27',
                    }),
                }),
            }
        }
    },
});

export const sidebar = {
    borderRight: "1px solid gray",
};
export const mainFill = {
    margin:"18px"
};
export const sectionDocumentSelected = {
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
};


