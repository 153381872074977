import * as React from 'react';
import {Fill, Left, Right} from "react-spaces";
import {ScicartaDocument} from "../types/DocumentData";
import {PaperViewer} from "./paperViewer/PaperViewer";
import {SelectedPublications} from "./SelectedPublications";
import {AppQuery, StoredFilterElement} from "../types/AppQuery";

interface SectionInformationPaperProps {
    clickedDocument?: ScicartaDocument;
    selectedDocuments : ReadonlyArray<ScicartaDocument>;
    hoveredDocument?: ScicartaDocument;
    onDeleteDocument: (documentId: string) => void;
    appQuery: AppQuery<StoredFilterElement>;
}

const boxPublications = {
    border: "1px solid #ccc",
    background: "white",
};

export const SectionInformationPaper: React.FunctionComponent<SectionInformationPaperProps> = (props) => {
    const viewerPaper = {
        background:"white",
        marginTop:"16px"
    };
    return (
        <Fill style={viewerPaper} >
            <Left size="50%" style={boxPublications}>
                <Fill scrollable={true}>
                    {props.hoveredDocument && <PaperViewer document={props.hoveredDocument} appQuery={props.appQuery} />}
                </Fill>
            </Left>
            <Right size="50%" style={boxPublications}>
                <SelectedPublications onDeleteDocument={props.onDeleteDocument} selectedDocuments={props.selectedDocuments}>
                    {props.clickedDocument && <PaperViewer document={props.clickedDocument} appQuery={props.appQuery} />}
                </SelectedPublications>
            </Right>
        </Fill>
    );
};
